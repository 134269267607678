import { OwnerService } from "@/services/";
import cloneDeep from "lodash/cloneDeep";
import { alpha as alphSort } from "@/utils/sort";

const defaultOwner = {
  name: null,
  phone: null,
  address: {
    street: null,
    city: null,
    postalCode: null,
    provinceId: null
  }
};

const state = {
  owner: null,
  owners: []
}

export const getters = {
  getOwner: state => state.owner,
  getOwners: state => state.owners.sort((a, b) => alphSort(a.name, b.name))
}

export const actions = {
  fetchOwner: ({ commit }, id) => {
    return OwnerService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit ('CONFIGURATION_OWNER_SET', response.data)
        resolve(response.data);
      })
    });
  },
  fetchOwners: ({ commit }) => {
    return OwnerService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_OWNERS_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchOwnersByProject: ({ commit }, projectId) => {
    return OwnerService.GetAllByProjectId(projectId).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_OWNERS_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveOwner: ({ commit }, params) => {
    return OwnerService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_OWNERS_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeOwner: ({ commit }, id) => {
    return OwnerService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_OWNERS_REMOVE', id);
        resolve();
      })
    });
  },
  resetOwner: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_OWNERS_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_OWNER_SET'(state, owner) {
    state.owner = owner;
    state.owner.address = state.owner.address || { ...defaultOwner.address };
  },
  'CONFIGURATION_OWNERS_SET'(state, owners) {
    state.owners = owners
  },
  'CONFIGURATION_OWNERS_SAVE' (state, owner) {
    let isExist = state.owners.find(n => n.id === owner.id);

    if (isExist) {
      var pos = state.owners.indexOf(isExist);
      state.owners.splice(pos, 1, owner);
    }
    else {
      state.owners.push(owner);
    }
  },
  'CONFIGURATION_OWNERS_REMOVE'(state, id) {
    let owner = state.owners.find(n => n.id === id);

    if (owner) {
      var pos = state.owners.indexOf(owner);
      state.owners.splice(pos, 1);
    }
  },
  'CONFIGURATION_OWNERS_SET_NEW' (state) {
    state.owner = cloneDeep(defaultOwner);
  }
}

export default { state, getters, actions, mutations }
