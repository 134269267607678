const state = {
  publicSignupVerifyCompany: null,
  publicSignupVerifyEmail: null,
  showNotifications: true,
}

export const getters = {
  showNotifications: state => state.showNotifications
}

export const actions = {
  setNotificationsDisplay: ({ commit }, toggle) => {
    commit('GLOBAL_SET_NOTIFICATIONS_DISPLAY', toggle)
  },
  setPublicSignupInfo: ({ commit }, payload) => {
    commit('PUBLIC_SIGNUP_VERIFY_COMPANY', payload.company);
    commit('PUBLIC_SIGNUP_VERIFY_EMAIL', payload.email);
  }
}

export const mutations = {
  'GLOBAL_SET_NOTIFICATIONS_DISPLAY' (state, toggle) {
    state.showNotifications = toggle;
  },
  'PUBLIC_SIGNUP_VERIFY_COMPANY' (state, value) {
    state.publicSignupVerifyCompany = value;
  },
  'PUBLIC_SIGNUP_VERIFY_EMAIL' (state, value) {
    state.publicSignupVerifyEmail = value;
  },
}

export default { state, getters, actions, mutations }
