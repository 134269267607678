import { sortOrder } from "@/utils/sort";
import { TradeAgreementService } from "@/services/"

const state = {
  tradeAgreement: null,
  tradeAgreements: []
}

export const getters = {
  getTradeAgreement: state => state.tradeAgreement,
  getTradeAgreements: state => state.tradeAgreements.sort(sortOrder)
}

export const actions = {
  fetchTradeAgreement: ({ commit }, id) => {
    return TradeAgreementService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TRADE_AGREEMENT_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchTradeAgreements: ({ commit }) => {
    return TradeAgreementService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TRADE_AGREEMENTS_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveTradeAgreement: ({ commit }, params) => {
    return TradeAgreementService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TRADE_AGREEMENTS_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeTradeAgreement: ({ commit }, id) => {
    return TradeAgreementService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TRADE_AGREEMENTS_REMOVE', id);
        resolve();
      })
    });
  },
  resetTradeAgreement: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_TRADE_AGREEMENT_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_TRADE_AGREEMENT_SET'(state, tradeAgreement) {
    state.tradeAgreement = tradeAgreement
  },
  'CONFIGURATION_TRADE_AGREEMENTS_SET'(state, tradeAgreements) {
    state.tradeAgreements = tradeAgreements
  },
  'CONFIGURATION_TRADE_AGREEMENTS_SAVE' (state, tradeAgreement) {
    let isExist = state.tradeAgreements.find(n => n.id === tradeAgreement.id);

    if (isExist) {
      var pos = state.tradeAgreements.indexOf(isExist);
      state.tradeAgreements.splice(pos, 1, tradeAgreement);
    }
    else {
      state.tradeAgreements.push(tradeAgreement);
    }
  },
  'CONFIGURATION_TRADE_AGREEMENTS_REMOVE'(state, id) {
    let tradeAgreement = state.tradeAgreements.find(n => n.id === id);

    if (tradeAgreement) {
      var pos = state.tradeAgreements.indexOf(tradeAgreement);
      state.tradeAgreements.splice(pos, 1);
    }
  },
  'CONFIGURATION_TRADE_AGREEMENT_SET_NEW' (state) {
    state.tradeAgreement = { name: null, code: null, sortOrder: 0 };
  }
}

export default { state, getters, actions, mutations }
