export const clean = (obj) => {
  let propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i++) {
    let propName = propNames[i];

    if (obj[propName] instanceof Object && !Array.isArray(obj[propName]))
      clean(obj[propName]);

    if (obj[propName] === null || obj[propName] === undefined || (obj[propName] instanceof Object && Object.keys(obj[propName]).length === 0)) {
      delete obj[propName];
    }
  }
}

export const transformToFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach(key => { formData.append(key, data[key]); });
  return formData;
}

export default { clean }
