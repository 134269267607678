import httpClient from "@/services/base.service.js";

export default {
  getAll: function () {
    return httpClient.get("/api/v1/category/get");
  },
  getByGrouping: function () {
    return httpClient.get("/api/v1/category/getbygrouping");
  },
  getTopLevel: function () {
    return httpClient.get("/api/v1/category/gettoplevel");
  },
  getLevelByParentId: function (id) {
    return httpClient.get("/api/v1/category/getlevelbyparentid/" + id);
  }
};
