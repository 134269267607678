export const TenderStages = Object.freeze({
  CLOSED:	'2501c6dc-361e-4970-b02b-40be2d3d8b08',
  CANCELLED: '47f26b13-e584-4654-b18a-43dfe3308563',
  OPEN: 'ba1408eb-83d9-4c94-a2ba-6d9436773b36',
  UNOFFICIAL_RESULTS: '7173009e-9821-4215-9692-b5cc26403b15',
  PREBID: '187158c8-6cb9-4067-9af6-bc1cade44f49',
  AWARDED: '80c4cd45-a72a-4d1e-877f-f39458f8eb02'
})

export const Invite2BidEmailStages = Object.freeze({
    COMPOSE: '6ddded3d-3400-4c51-a577-af0433228dc3',
    PREVIEW: '2b584604-c413-49bb-9a11-fb889e9ebb12',
    CONFIRMED: '0b5f13b9-ac5a-4ea1-96ca-33b3c8191453'
})

export const PublicCompanyJoinAcceptanceStatus = Object.freeze({
  Approved: 1,
  Rejected: 0,
  Pending: 2
})

export default { TenderStages, Invite2BidEmailStages, PublicCompanyJoinAcceptanceStatus };
