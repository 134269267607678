import httpClient from "@/services/base.service.js";

export default {
    excel: function (params) {
        return httpClient({ url: "/api/v1/company/excel", method: "POST", responseType: "blob", data: params });
    },
    getAll: function () {
        return httpClient.get("/api/v1/company/get");
    },
    getLookup: function () {
        return httpClient.get("/api/v1/company/getlookup");
    },
    getById: function (id) {
        return httpClient.get("/api/v1/company/get/" + id);
    },
    getByTypeahead: function (query) {
        return httpClient.get("/api/v1/company/gettbyypeahead?query=" + query);
    },
    getMemberships: function (id) {
      return httpClient.get("/api/v1/company/getmemberships/" + id);
    },
    getNotifications: function () {
        return httpClient.get("/api/v1/company/getnotifications");
    },
    getUserEmails: function (companyIds) {
        return httpClient.post('/api/v1/company/getuseremails', companyIds)
    },
    getUsers: function (payload) {
      return httpClient.post('/api/v1/company/users', payload)
    },
    getAlias: function (company) {
        return httpClient({ method: 'post', url: '/api/v1/company/alias', data: company });
    },
    save: function ({ data, isEdit }) {
        let http = isEdit ? 'put' : 'post'
        let url = isEdit ? '/api/v1/company/put/' + data.id : '/api/v1/company/post';
        return httpClient({ method: http, url: url, data: data });
    },
    search: function (searchCriteria) {
        return httpClient.post('/api/v1/company/search', searchCriteria)
    },
    signupCreate: function (data) {
      return httpClient.post('/api/v1/public/signup/create', data)
    },
    signupCompanyLookup: function (alias) {
      return httpClient.get('/api/v1/public/signup/companylookup?alias=' + alias)
    },
    signupJoin: function (data) {
      return httpClient.post('/api/v1/public/signup/join', data)
    },
    saveCompanyFile(uploadedFile, companyId, uploadType) {
        var formData = new FormData();

        formData.append("companyId", companyId);
        formData.append("uploadedFile", uploadedFile);
        formData.append("uploadType", uploadType);

        return httpClient.post('/api/v1/company/savefile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    }
};
