import httpClient from "@/services/base.service.js";

export default {
  dismiss: function (id) {
    return httpClient.post('/api/v1/dismissproject/dismiss/' + id)
  },
  search: function (searchCriteria) {
    return httpClient.post('/api/v1/dismissproject/search', searchCriteria)
  }
};
