import axios from 'axios'
import { store } from '@/store/'
import router from "@/router/";
import JwtHelper from "@/utils/jwtHelper";
import { EventBus } from "@/event-bus/event-bus.js";

export const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json'
    }
})

httpClient.interceptors.request.use(
  function (config) {
    let token = JwtHelper.getBearerToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }

    config.onDownloadProgress = (progressEvent) => EventBus.$emit('api-call-progress', progressEvent)

    return config;
  },
  function (error) {
    return Promise.reject(error)
  }
)

httpClient.interceptors.response.use(response => response, error => {
  const status = error.response ? error.response.status : null;

  if (status === 401) {
    return resetTokenAndReattemptRequest(error);
  }

  return Promise.reject(error);
});

let subscribers = [];

async function resetTokenAndReattemptRequest (error) {
  try {
    const { response: errorResponse } = error;

    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(access_token => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
        resolve(axios(errorResponse.config));
      });
    });

    if (!store.state.auth.isRefreshing) {
      store.dispatch('setRefreshingState', true);
      let refreshToken = JwtHelper.getRefreshToken();

      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + `/api/v1/user/${ refreshToken }/refresh`);

        if (!response.data) {
          return Promise.reject(error);
        }

        const newToken = response.data.token;
        JwtHelper.setToken(JwtHelper.keys.jwt, response.data);
        store.dispatch('setRefreshingState', false);
        onAccessTokenFetched(newToken);
      }
      catch {
        router.push({ path: '/logout' });
      }
    }

    return retryOriginalRequest;
  }
  catch (err) {
    return Promise.reject(err);
  }
}

function onAccessTokenFetched(access_token) {
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default httpClient;
