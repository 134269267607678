import httpClient from "@/services/base.service.js";

export default {
  getKeywordDensityByProjectId: function (keyword, projectId) {
    let url = `api/v1.0/DocumentSearch/keyword/${keyword}/project/${projectId}`;
    return httpClient({ baseURL: process.env.VUE_APP_DOCUMENT_SEARCH_API_URL, url: url, method: "GET" });
  },
  getKeywordHighlightsByProjectId: function (keyword, projectId) {
    let url = `api/v1.0/DocumentSearch/keyword/${keyword}/project/${projectId}/highlights`;
    return httpClient({ baseURL: process.env.VUE_APP_DOCUMENT_SEARCH_API_URL, url: url, method: "GET" });
  }
};
