import httpClient from "@/services/base.service.js";

let apiBase = '/api/v1/bidinvitationlist';

export default {
    save: function (params) {
        return httpClient.post(apiBase, params)
    },
    getForLoggedInUser: function () {
      return httpClient.get(`${apiBase}/listsforloggedinuser`);
    },
    listNameExists: function (listName) {
        return httpClient.get(`${apiBase}/listnameexists?name=${escape(listName)}`);
    }
};
