import { sortOrder } from "@/utils/sort";
import { ProcurementTypeService } from "@/services/";

const state = {
  procurementType: null,
  procurementTypes: []
}

export const getters = {
  getProcurementType: state => state.procurementType,
  getProcurementTypes: state => state.procurementTypes.sort(sortOrder)
}

export const actions = {
  fetchProcurementType: ({ commit }, id) => {
    return ProcurementTypeService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_PROCUREMENT_TYPE_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchProcurementTypes: ({ commit }) => {
    return ProcurementTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_PROCUREMENT_TYPES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveProcurementType: ({ commit }, params) => {
    return ProcurementTypeService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_PROCUREMENT_TYPE_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeProcurementType: ({ commit }, id) => {
    return ProcurementTypeService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_PROCUREMENT_TYPE_REMOVE', id);
        resolve();
      })
    });
  },
  resetProcurementType: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_PROCUREMENT_TYPE_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_PROCUREMENT_TYPE_SET'(state, procurementType) {
    state.procurementType = procurementType
  },
  'CONFIGURATION_PROCUREMENT_TYPES_SET'(state, procurementTypes) {
    state.procurementTypes = procurementTypes
  },
  'CONFIGURATION_PROCUREMENT_TYPE_SAVE' (state, procurementType) {
    let isExist = state.procurementTypes.find(n => n.id === procurementType.id);

    if (isExist) {
      var pos = state.procurementTypes.indexOf(isExist);
      state.procurementTypes.splice(pos, 1, procurementType);
    }
    else {
      state.procurementTypes.push(procurementType);
    }
  },
  'CONFIGURATION_PROCUREMENT_TYPE_REMOVE'(state, id) {
    let procurementType = state.procurementTypes.find(n => n.id === id);

    if (procurementType) {
      var pos = state.procurementTypes.indexOf(procurementType);
      state.procurementTypes.splice(pos, 1);
    }
  },
  'CONFIGURATION_PROCUREMENT_TYPE_SET_NEW' (state) {
    state.procurementType = { name: null, sortOrder: 0 };
  }
}

export default { state, getters, actions, mutations }
