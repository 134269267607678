import { date } from "@/utils/sort";
import { NewsService } from "@/services/";

const state = {
  news: null,
  newsList: [],
  isNewsEnabled: false
}

const _reduceArrayUniqueItemCountFn = function (acc, curr) { acc[curr] ? acc[curr]++ : acc[curr] = 1; return acc; }

export const getters = {
  getNews: state => state.news,
  getNewsList: state => state.newsList.sort((a, b) => date(b.createdOn, a.createdOn)),
  getNewsListZoneEntriesCount: state => state.newsList.flatMap(r => r.zoneIds).reduce(_reduceArrayUniqueItemCountFn, {}),
  getIsNewsEnabled: state => state.isNewsEnabled
}

export const actions = {
  fetchNews: ({ commit }, id) => {
    return NewsService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_NEWS_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchNewsList: ({ commit }) => {
    return NewsService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_NEWS_LIST_SET', response.data);
        !response.data.length || commit('CONFIGURATION_NEWS_ENABLED_SET', response.data[0].isActive);
        resolve(response.data);
      })
    });
  },
  toggleNewsEnabled: ({ commit }, direction) => {
    return NewsService.toggle(direction).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_NEWS_ENABLED_SET', direction);
        resolve(direction);
      })
    });
  },
  saveNews: ({ commit }, params) => {
    return NewsService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_NEWS_SAVE', response.data);
        resolve(response.data);
      })
    });
  },
  removeNews: ({ commit }, id) => {
    return NewsService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_NEWS_REMOVE', id);
        resolve();
      })
    });
  },
  updateNews: ({ commit }, news) => {
    return NewsService.update(news).then(response => {
      return new Promise(resolve => {
        commit('CONFIGURATION_NEWS_SET', response.data);
        resolve(response.data);
      });
    });
  },
  resetNews: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_NEWS_SET_NEW');
      resolve();
    });
  },
}

export const mutations = {
  'CONFIGURATION_NEWS_SET'(state, news) {
    state.news = news
  },
  'CONFIGURATION_NEWS_ENABLED_SET' (state, isEnabled) {
    state.isNewsEnabled = isEnabled;
  },
  'CONFIGURATION_NEWS_LIST_SET'(state, newsList) {
    state.newsList = newsList
  },
  'CONFIGURATION_NEWS_SAVE' (state, news) {
    let isExist = state.newsList.find(n => n.id === news.id);

    if (isExist) {
      var pos = state.newsList.indexOf(isExist);
      state.newsList.splice(pos, 1, news);
    }
    else {
      state.newsList.push(news);
    }
  },
  'CONFIGURATION_NEWS_REMOVE'(state, id) {
    let news = state.newsList.find(n => n.id === id);

    if (news) {
      var pos = state.newsList.indexOf(news);
      state.newsList.splice(pos, 1);
    }
  },
  'CONFIGURATION_NEWS_SET_NEW' (state) {
    state.news = { title: null, summary: null, link: null, createdOn: null, zoneIds: [] };
  }
}

export default { state, getters, actions, mutations }
