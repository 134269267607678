import httpClient from "@/services/base.service.js";
import { transformToFormData } from "@/utils/object";

const apiBase = '/api/v1/addressbook/';

export default {
  getForInviteToBid: function () {
    return httpClient.get(`${apiBase}getforinvitetobid/`);
  },
  getForLoggedInUser: function () {
    return httpClient.get(`${apiBase}getforloggedinuser/`);
  },
  getById: function (id) {
    return httpClient.get(`${apiBase}get/` + id);
  },
  save: function (data) {
    if (!data.isEdit) {
      let formData = transformToFormData({ ...data.addressBook });

      return httpClient.post(`${apiBase}post/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    }
    else {
      return httpClient.put(`${apiBase}put/${data.addressBook.id}`, data.addressBook);
    }
  },
  delete: function (id) {
    return httpClient.delete(`${apiBase}delete/` + id);
  }
};
