import { sortOrder } from "@/utils/sort";
import { TenderStageService } from "@/services/";

const state = {
  tenderStage: null,
  tenderStages: []
}

export const getters = {
  getTenderStage: state => state.tenderStage,
  getTenderStages: state => state.tenderStages.sort(sortOrder)
}

export const actions = {
  fetchTenderStage: ({ commit }, id) => {
    return TenderStageService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TENDER_STAGE_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchTenderStages: ({ commit }) => {
    return TenderStageService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TENDER_STAGES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveTenderStage: ({ commit }, params) => {
    return TenderStageService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TENDER_STAGES_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeTenderStage: ({ commit }, id) => {
    return TenderStageService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TENDER_STAGES_REMOVE', id);
        resolve();
      })
    });
  },
  resetTenderStage: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_TENDER_STAGE_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_TENDER_STAGE_SET'(state, tenderStage) {
    state.tenderStage = tenderStage
  },
  'CONFIGURATION_TENDER_STAGES_SET'(state, tenderStages) {
    state.tenderStages = tenderStages
  },
  'CONFIGURATION_TENDER_STAGES_SAVE' (state, tenderStage) {
    let isExist = state.tenderStages.find(n => n.id === tenderStage.id);

    if (isExist) {
      var pos = state.tenderStages.indexOf(isExist);
      state.tenderStages.splice(pos, 1, tenderStage);
    }
    else {
      state.tenderStages.push(tenderStage);
    }
  },
  'CONFIGURATION_TENDER_STAGES_REMOVE'(state, id) {
    let tenderStage = state.tenderStages.find(n => n.id === id);

    if (tenderStage) {
      var pos = state.tenderStages.indexOf(tenderStage);
      state.tenderStages.splice(pos, 1);
    }
  },
  'CONFIGURATION_TENDER_STAGE_SET_NEW' (state) {
    state.tenderStage = { name: null, code: null, sortOrder: 0 };
  }
}

export default { state, getters, actions, mutations }
