import httpClient from "@/services/base.service.js";

export default {
  calendar: function(searchCriteria) {
    return httpClient.post("/api/v1/dashboard/calendar", searchCriteria);
  },
  get admin() {
    return {
      overview: function () {
        return httpClient.get('/api/v1/dashboard/admin/overview')
      },
      dismissalbtaddenda: function (searchCriteria) {
        return httpClient.post("/api/v1/dashboard/admin/dismissalbtaddenda", searchCriteria);
      },
      dismissalsearch: function (searchCriteria) {
        return httpClient.post("/api/v1/dashboard/admin/dismissalsearch", searchCriteria);
      },
      search: function (searchCriteria) {
        return httpClient.post("/api/v1/dashboard/admin/search", searchCriteria);
      }
    }
  },
  get member() {
    return {
      createdprojects: function (searchCriteria) {
        return httpClient.post("/api/v1/dashboard/member/createdprojects", searchCriteria);
      },
      invitedprojects: function (searchCriteria) {
        return httpClient.post("/api/v1/dashboard/member/invitedprojects", searchCriteria);
      },
      membership: function () {
        return httpClient.get("/api/v1/dashboard/member/membership");
      },
      dismissalsearch: function (searchCriteria) {
        return httpClient.post("/api/v1/dashboard/member/dismissalsearch", searchCriteria);
      },
      search: function (searchCriteria) {
        return httpClient.post("/api/v1/dashboard/member/search", searchCriteria);
      }
    }
  }
};
