import { sortOrder } from "@/utils/sort";
import { BidInvitationStatusTypeService } from "@/services/"

const state = {
  statusTypes: [],
}

export const getters = {
  getBidInvitationStatusTypes: state => state.statusTypes.sort(sortOrder),
}

export const actions = {
  fetchBidInvitationStatusTypes: ({ commit }) => {
        return BidInvitationStatusTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_BIDINVITATION_STATUSTYPES_SET', response.data);
        resolve(response.data);
      })
    });
  },
}

export const mutations = {
  'CONFIGURATION_BIDINVITATION_STATUSTYPES_SET'(state, statusTypes) {
    state.statusTypes = statusTypes
  }
}

export default { state, getters, actions, mutations }
