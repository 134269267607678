import { sortOrder } from "@/utils/sort";
import { BidInvitationSelectionTypeService } from "@/services/"

const state = {
  selectionTypes: [],
}

export const getters = {
  getBidInvitationSelectionTypes: state => state.selectionTypes.sort(sortOrder),
}

export const actions = {
  fetchBidInvitationSelectionTypes: ({ commit }) => {
      return BidInvitationSelectionTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_BIDINVITATION_SELECTIONTYPES_SET', response.data);
        resolve(response.data);
      })
    });
  },
}

export const mutations = {
  'CONFIGURATION_BIDINVITATION_SELECTIONTYPES_SET'(state, selectionTypes) {
    state.selectionTypes = selectionTypes
  }
}

export default { state, getters, actions, mutations }
