import httpClient from "@/services/base.service.js";

export default {
  getAll: function () {
    return httpClient.get("/api/v1/advertisementgroup/");
  },
  getListings: function () {
    return httpClient.get("/api/v1/advertisementgroup/listings");
  },
  getRandomAd: function (code, count) {
    return httpClient.get(`/api/v1/advertisementgroup/${code}/random/${count}`);
  },
  getAllAds: function (code) {
    return httpClient.get(`/api/v1/advertisementgroup/${code}`);
  },
  toggle: function (direction) {
    return httpClient.put('/api/v1/advertisementgroup/toggle', direction);
  }
};
