import httpClient from "@/services/base.service.js";

export default {
  excel: function (params) {
    return httpClient({ url: '/api/v1/bidinvitation/excel', method: "POST", responseType: "blob", data: params });
  },
  followUp: function (params) {
    return httpClient.post('/api/v1/bidinvitation/followup/', params);
  },
  remind: function (bidInvitationCompanyId) {
      return httpClient.post('/api/v1/bidinvitation/remind/' + bidInvitationCompanyId);
  },
  respondToInvitation: function (bidInvitationCompanyId, status) {
    return httpClient.get(`/api/v1/bidinvitation/respondtoinvitation?id=${bidInvitationCompanyId}&status=${status}`)
  },
  save: function (params) {
    return httpClient.post('/api/v1/bidinvitation', params)
  }
};
