import httpClient from "@/services/base.service.js";

export default {
  delete: function (id) {
    return httpClient.delete("/api/v1/companysearch/delete/" + id);
  },
  getAll: function () {
    return httpClient.get("/api/v1/companysearch/get");
  },
  getById: function (id) {
    return httpClient.get("/api/v1/companysearch/get/" + id);
  },
  save: function (params) {
    return httpClient.post('/api/v1/companysearch/post', params)
  }
};
