import { sortOrder } from "@/utils/sort";
import { ContactTypeService } from "@/services/"

const state = {
  contactType: null,
  contactTypes: [],
}

export const getters = {
  getContactType: state => state.contactType,
  getContactTypes: state => state.contactTypes.sort(sortOrder),
}

export const actions = {
  fetchContactType: ({ commit }, id) => {
    return ContactTypeService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_CONTACT_TYPE_SET', response.data)
        resolve(response.data);
      })
    });
  },
  fetchContactTypes: ({ commit }) => {
    return ContactTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_CONTACT_TYPES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveContactType: ({ commit }, params) => {
    return ContactTypeService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_CONTACT_TYPES_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeContactType: ({ commit }, id) => {
    return ContactTypeService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_CONTACT_TYPES_REMOVE', id);
        resolve();
      })
    });
  },
  resetContactType: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_CONTACT_TYPE_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_CONTACT_TYPE_SET'(state, contactType) {
    state.contactType = contactType;
  },
  'CONFIGURATION_CONTACT_TYPE_SET_NEW' (state) {
    state.contactType = { name: null, sortOrder: 0 };
  },
  'CONFIGURATION_CONTACT_TYPES_SET'(state, contactTypes) {
    state.contactTypes = contactTypes
  },
  'CONFIGURATION_CONTACT_TYPES_SAVE' (state, contactType) {
    let isExist = state.contactTypes.find(n => n.id === contactType.id);

    if (isExist) {
      var pos = state.contactTypes.indexOf(isExist);
      state.contactTypes.splice(pos, 1, contactType);
    }
    else {
      state.contactTypes.push(contactType);
    }
  },
  'CONFIGURATION_CONTACT_TYPES_REMOVE'(state, id) {
    let contactType = state.contactTypes.find(n => n.id === id);

    if (contactType) {
      var pos = state.contactTypes.indexOf(contactType);
      state.contactTypes.splice(pos, 1);
    }
  }
}

export default { state, getters, actions, mutations }
