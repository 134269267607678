import uuid from 'uuid/v1'
import moment from 'moment/moment'
import cloneDeep from "lodash/cloneDeep"
import { date as dateSort } from "@/utils/sort";
import { ProjectService, ProjectSearchService, BidInvitationService } from "@/services/"

const emptyProject = {
  id: null,
  ocaaNumber: null,
  lcaNumber: null,
  belongZoneId: null,
  sharedZoneIds: [],
  title: null,
  ownerSolicitationNumber: null,
  addresses: [],
  awardedDate: null,
  closingDate: null,
  otherClosingDate: null,
  submitBidsTo: null,
  obtainBidDocuments: null,
  bonding: null,
  siteMeetings: [],
  addenda: 0,
  tenderStageId: null,
  fundingId: null,
  procurementTypeId: null,
  classificationTypeId: null,
  classificationOfWorkIds: [],
  ownerTypeId: null,
  budget: null,
  scopeOfWork: null,
  comments: null,
  disclaimer: null,
  tradeAgreementIds: [],
  showProject: false,
  enableRequestToBid: true,
  isPrivate: false,
  rootFolderId: null,
  owner: null,
  contacts: [],
  documents: [],
  adminNotes: [],
  prebidNotes: [],
  followUpTasks: [],
  bids: [],
  followers: [],
  timeZone: {
    code: null,
    name: null,
    id: null
  },
  bidInvitations: []
};

const defaultProjectContact = {
  companyName: null,
  showInfo: false,
  address: { street: null, city: null, postalCode: null, provinceId: null },
  phone: null,
  contactTypeId: null,
  additionalInformation: null
}

export const state = {
  project: { ...emptyProject },
  projects: [],
  projectsForLoggedInUser: [],
  siteMeeting: {},
  bid: {},
  address: {},
  owner: {},
  contact: {},
  note: {},
  prebidNote: {},
  task: {},
  savedSearches: []
};

export const getters = {
  getProject: state => state.project,
  getProjects: state => state.projects,
  getProjectAddress: state => state.address,
  getProjectAddresses: state => state.project.addresses,
  getProjectBid: state => state.bid,
  getProjectBids: state => state.project.bids,
  getProjectContact: state => state.contact,
  getProjectContacts: state => state.project.contacts,
  getProjectSavedSearches: state => state.savedSearches,
  getProjectSiteMeeting: state => state.siteMeeting,
  getProjectSiteMeetings: state => state.project.siteMeetings.sort((a, b) => dateSort(a.date, b.date)),
  getProjectNote: state => state.note,
  getProjectNotes: state => state.project.adminNotes,
  getProjectOwner: state => state.owner,
  getProjectPrebidNote: state => state.prebidNote,
  getProjectPrebidNotes: state => state.project.prebidNotes.sort((a, b) => dateSort(a.createdOn, b.createdOn)),
  getProjectTask: state => state.task,
  getProjectTasks: state => state.project.followUpTasks
};

export const actions = {
  createNewProject: ({ commit }) => {
    let newProject = Object.assign({}, emptyProject);
    newProject.id = uuid();
    newProject.rootProjectId = uuid();

    commit('PROJECT_SET_NEW', newProject);
  },
  fetchProject: ({ commit }, id) => {
    if (!id) {
      return new Promise((resolve) => {
        commit('PROJECT_SET_NEW');
        resolve();
      })
    }

    return ProjectService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('PROJECT_SET', response.data);
        resolve(response.data);
      })
    })
  },
  fetchProjectDetails: ({ commit }, id) => {
    return ProjectService.getDetailsById(id).then(response => {
      return new Promise((resolve) => {
        commit('PROJECT_SET', response.data);
        resolve(response.data);
      })
    })
  },
  fetchProjects: ({ commit }, params) => {
    return ProjectService.search(params).then(response => {
      return new Promise((resolve) => {
        commit('PROJECT_SET_ALL', response.data.results)
        resolve(response.data);
      });

    });
  },
  fetchProjectsForLoggedInUser: ({ commit }) => {
      return ProjectService.getForLoggedInUser().then(response => {
          return new Promise((resolve) => {
              commit('PROJECT_SET_FOR_LOGGEDINUSER', response.data.results)
              resolve(response.data);
          });
      });
  },
  fetchProjectSearches: ({ commit }) => {
    return ProjectSearchService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('PROJECT_SEARCHES_SET', response.data)
        resolve(response.data);
      });
    });
  },
  saveProject: ({ commit }, params) => {
    params.data.addresses = state.project.addresses;
    params.data.siteMeetings = state.project.siteMeetings;
    params.data.contacts = state.project.contacts;
    params.data.bids = state.project.bids;
    params.data.adminNotes = state.project.adminNotes;
    params.data.prebidNotes = state.project.prebidNotes;
    params.data.followUpTasks = state.project.followUpTasks;
    params.data.owner = state.project.owner;

    // i hate datetime, and timezones, and kinds
    if (params.data.awardedDate) params.data.awardedDate = params.data.awardedDate.substr(0, 19);
    if (params.data.closingDate) params.data.closingDate = params.data.closingDate.substr(0, 19);
    if (params.data.otherClosingDate) params.data.otherClosingDate = params.data.otherClosingDate.substr(0, 19);
    params.data.siteMeetings.forEach(item => { if (item.date) item.date = item.date.substr(0, 19); });
    params.data.followUpTasks.forEach(item => { if (item.dueDate) item.dueDate = item.dueDate.substr(0, 19); });

    return ProjectService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('PROJECT_SET', response.data)
        resolve(response.data);
      });
    });
  },
  setProject: ({ commit }, project) => {
      return new Promise((resolve) => {
        commit('PROJECT_SET', project)
        resolve(project);
      });
  },
  saveProjectBidInvitation: ({ commit }, params) => {
    return BidInvitationService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('PROJECT_BIDINVITATION_ADD', response.data)
        resolve(response.data);
      });
    });
  },
  sendProjectITBFollowUp: ({ commit }, params) => {
    return BidInvitationService.followUp(params).then(response => {
      return new Promise((resolve) => {
        commit('PROJECT_BIDINVITATION_FOLLOWUP', params.BidInvitationInvitees)
        resolve(response.data);
      });
    });
  },
  saveProjectSearch: ({ commit }, params) => {
    return ProjectSearchService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('PROJECT_SEARCHES_ADD', response.data)
        resolve(response.data);
      });
    });
  },
  removeProjectSearch: ({ commit }, id) => {
    return ProjectSearchService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('PROJECT_SEARCHES_REMOVE', id)
        resolve();
      });
    })
  },
  fetchProjectAddress: ({ commit }, id) => {
    return new Promise((resolve) => {
      let address = state.project.addresses.find(p => p.id === id);

      if (address) {
        commit('PROJECT_ADDRESS_SET', address);
        resolve(address);
      }
    });
  },
  saveProjectAddress: ({ commit }, address) => {
    return new Promise(resolve => {
      commit('PROJECT_ADDRESS_SAVE', address);
      resolve()
    });
  },
  resetProjectAddress: ({ commit }) => {
    return new Promise(resolve => {
      commit('PROJECT_ADDRESS_SET_NEW');
      resolve();
    })
  },
  removeProjectAddress: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('PROJECT_ADDRESS_REMOVE', id);
      resolve();
    });
  },
  resetProjectOwner: ({ commit }) => {
    return new Promise(resolve => {
      commit('PROJECT_OWNER_SET_NEW');
      resolve();
    })
  },
  fetchProjectOwner: ({ commit }) => {
    return new Promise((resolve) => {
      let owner = state.project.owner;

      if (owner) {
        commit('PROJECT_OWNER_SET', owner);
        resolve();
      }
    });
  },
  saveProjectOwner: ({ commit }, owner) => {
    return new Promise(resolve => {
      commit('PROJECT_OWNER_SAVE', owner);
      resolve()
    });
  },
  setProjectBids: ({ commit }, bids) => {
    return new Promise((resolve) => {
      commit('PROJECT_BIDS_SET', bids);
      resolve(bids);
    });
  },
  fetchProjectBid: ({ commit }, id) => {
    return new Promise((resolve) => {
      let bid = state.project.bids.find(p => p.id === id);

      if (bid) {
        commit('PROJECT_BID_SET', bid);
        resolve(bid);
      }
    });
  },
  saveProjectBid: ({ commit }, bid) => {
    return new Promise(resolve => {
      commit('PROJECT_BID_SAVE', bid);
      resolve()
    });
  },
  resetProjectBid: ({ commit }) => {
    return new Promise(resolve => {
      commit('PROJECT_BID_SET_NEW');
      resolve();
    })
  },
  removeProjectBid: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('PROJECT_BID_REMOVE', id);
      resolve();
    });
  },
  fetchProjectSiteMeeting: ({ commit }, id) => {
    return new Promise((resolve) => {
      let meeting = state.project.siteMeetings.find(p => p.id === id);

      if (meeting) {
        commit('PROJECT_SITE_MEETING_SET', meeting);
        resolve(meeting);
      }
    });
  },
  saveProjectSiteMeeting: ({ commit }, meeting) => {
    return new Promise(resolve => {
      commit('PROJECT_SITE_MEETING_SAVE', meeting);
      resolve()
    });
  },
  resetProjectSiteMeeting: ({ commit }) => {
    return new Promise(resolve => {
      commit('PROJECT_SITE_MEETING_SET_NEW');
      resolve();
    })
  },
  removeProjectSiteMeeting: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('PROJECT_SITE_MEETING_REMOVE', id);
      resolve();
    });
  },
  fetchProjectContact: ({ commit }, id) => {
    return new Promise((resolve) => {
      let contact = state.project.contacts.find(p => p.id === id);

      if (contact) {
        commit('PROJECT_CONTACT_SET', contact);
        resolve(contact);
      }
    });
  },
  saveProjectContact: ({ commit }, contact) => {
    return new Promise(resolve => {
      commit('PROJECT_CONTACT_SAVE', contact);
      resolve()
    });
  },
  resetProjectContact: ({ commit }) => {
    return new Promise(resolve => {
      commit('PROJECT_CONTACT_SET_NEW');
      resolve();
    })
  },
  removeProjectContact: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('PROJECT_CONTACT_REMOVE', id);
      resolve();
    });
  },
  fetchProjectNote: ({ commit }, id) => {
    return new Promise((resolve) => {
      let note = state.project.adminNotes.find(p => p.id === id);

      if (note) {
        commit('PROJECT_NOTE_SET', note);
        resolve(note);
      }
    });
  },
  saveProjectNote: ({ commit }, note) => {
    note.date = moment.utc(); // set created date

    return new Promise(resolve => {
      commit('PROJECT_NOTE_SAVE', note);
      resolve()
    });
  },
  resetProjectNote: ({ commit, rootState }) => {
    return new Promise(resolve => {
      let user = rootState.auth.user;
      commit('PROJECT_NOTE_SET_NEW', user);
      resolve();
    })
  },

  // Project Prebid Notes

  fetchProjectPrebidNote: ({ commit }, id) => {
    return new Promise((resolve) => {
      let note = state.project.prebidNotes.find(p => p.id === id);

      if (note) {
        commit('PROJECT_PREBID_NOTE_SET', note);
        resolve(note);
      }
    });
  },
  saveProjectPrebidNote: ({ commit }, note) => {
    note.date = moment.utc(); // set created date

    return new Promise(resolve => {
      commit('PROJECT_PREBID_NOTE_SAVE', note);
      resolve()
    });
  },
  resetProjectPrebidNote: ({ commit, rootState }) => {
    return new Promise(resolve => {
      let user = rootState.auth.user;
      commit('PROJECT_PREBID_NOTE_SET_NEW', user);
      resolve();
    })
  },

  fetchProjectTask: ({ commit }, id) => {
    return new Promise((resolve) => {
      let task = state.project.followUpTasks.find(p => p.id === id);

      if (task) {
        commit('PROJECT_TASK_SET', task);
        resolve(task);
      }
    });
  },
  saveProjectTask: ({ commit }, task) => {
    task.date = moment.utc(); // set created date

    return new Promise(resolve => {
      commit('PROJECT_TASK_SAVE', task);
      resolve()
    });
  },
  updateProjectTaskCompletedFlag: ({ commit }, args) => {
    return new Promise((resolve) => {
      commit('PROJECT_TASK_UPDATE_COMPLETED_FLAG', args);
      resolve();
    });
  },
  resetProjectTask: ({ commit }) => {
    return new Promise(resolve => {
      commit('PROJECT_TASK_SET_NEW');
      resolve();
    })
  },
  removeProjectTask: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('PROJECT_TASK_REMOVE', id);
      resolve();
    });
  }
};

export const mutations = {
  'PROJECT_SET'(state, project) {
    state.project = Object.assign({}, emptyProject, project);
  },
  'PROJECT_SET_ALL'(state, projects) {
    state.projects = projects
  },
  'PROJECT_SET_FOR_LOGGEDINUSER'(state, projects) {
      state.projectsForLoggedInUser = projects;
  },
  'PROJECT_SET_NEW'(state) {
    let newProject = cloneDeep(emptyProject);
    newProject.id = uuid();
    newProject.rootFolderId = uuid();

    state.project = newProject
  },
  'PROJECT_ADDRESS_SET_NEW'(state) {
    state.address = { id: uuid(), address: null, city: null, province: null, postalcode: null }
  },
  'PROJECT_ADDRESS_SET'(state, address) {
    state.address = address
  },
  'PROJECT_ADDRESS_SAVE'(state, address) {
    let isExist = state.project.addresses.find(n => n.id === address.id);

    if (isExist) {
      var pos = state.project.addresses.indexOf(isExist);
      state.project.addresses.splice(pos, 1, address);
    }
    else {
      state.project.addresses.push(address);
    }
  },
  'PROJECT_ADDRESS_REMOVE'(state, id) {
    let address = state.project.addresses.find(n => n.id === id);

    if (address) {
      var pos = state.project.addresses.indexOf(address);
      state.project.addresses.splice(pos, 1);
    }
  },
  'PROJECT_OWNER_SET_NEW'(state) {
    state.owner = { id: uuid(), address: {}, showInfo: false, additionalInformation: null };
  },
  'PROJECT_OWNER_SET' (state, owner) {
    state.owner = owner;
  },
  'PROJECT_OWNER_SAVE' (state, owner) {
    state.project.owner = owner;
  },
  'PROJECT_BID_SET_NEW'(state) {
    state.bid = { id: uuid(), awardedAmount: null, submittedAmount: null, company: { address: {}, userCount: 0 }, participantTypeId: null };
  },
  'PROJECT_BID_SET'(state, bid) {
    state.bid = bid
  },
  'PROJECT_BIDS_SET'(state, bids) {
    state.project.bids = bids;
  },
  'PROJECT_BIDINVITATION_FOLLOWUP' (state, bidInvitations) {
    bidInvitations.forEach(invite => {
      // since we're sending back a partial collection of itself, we can guarantee the records will exist.
      state.project.bidInvitations.find(n => n.id == invite.id).lastContactDate = moment().format();
    })
  },
  'PROJECT_BIDINVITATION_ADD'(state, bidInvitation) {
    state.project.bidInvitations.push(bidInvitation);
  },
  'PROJECT_BID_SAVE'(state, bid) {
    let isExist = state.project.bids.find(n => n.id === bid.id);

    if (isExist) {
      var pos = state.project.bids.indexOf(isExist);
      state.project.bids.splice(pos, 1, bid);
    }
    else {
      state.project.bids.push(bid);
    }
  },
  'PROJECT_BID_REMOVE'(state, id) {
    let bid = state.project.bids.find(n => n.id === id);

    if (bid) {
      var pos = state.project.bids.indexOf(bid);
      state.project.bids.splice(pos, 1);
    }
  },
  'PROJECT_SEARCHES_ADD'(state, search) {
    state.savedSearches.push(search)
  },
  'PROJECT_SEARCHES_SET'(state, searches) {
    state.savedSearches = searches
  },
  'PROJECT_SEARCHES_REMOVE'(state, id) {
    state.savedSearches = [...state.savedSearches.filter(i => i.id !== id)];
  },
  'PROJECT_SITE_MEETING_SET_NEW'(state) {
    state.siteMeeting = { id: uuid(), date: null, priority: null, text: null }
  },
  'PROJECT_SITE_MEETING_SET'(state, meeting) {
    state.siteMeeting = meeting
  },
  'PROJECT_SITE_MEETING_SAVE'(state, meeting) {
    let isExist = state.project.siteMeetings.find(n => n.id === meeting.id);

    if (isExist) {
      var pos = state.project.siteMeetings.indexOf(isExist);
      state.project.siteMeetings.splice(pos, 1, meeting);
    }
    else {
      state.project.siteMeetings.push(meeting);
    }
  },
  'PROJECT_SITE_MEETING_REMOVE'(state, id) {
    let meeting = state.project.siteMeetings.find(n => n.id === id);

    if (meeting) {
      var pos = state.project.siteMeetings.indexOf(meeting);
      state.project.siteMeetings.splice(pos, 1);
    }
  },
  'PROJECT_CONTACT_SET_NEW'(state) {
    state.contact = { id: uuid(), ...defaultProjectContact };
  },
  'PROJECT_CONTACT_SET'(state, contact) {
    state.contact = contact;
    state.contact.address = state.contact.address || { ...defaultProjectContact.address };
  },
  'PROJECT_CONTACT_SAVE'(state, contact) {
    // will want to clean this up - yuck!
    let isExist = state.project.contacts.find(c => c.id === contact.id);

    if (isExist) {
      var pos = state.project.contacts.indexOf(isExist);
      state.project.contacts.splice(pos, 1, contact);
    } else {
      state.project.contacts.push(contact);
    }
  },
  'PROJECT_CONTACT_REMOVE'(state, id) {
    let contact = state.project.contacts.find(n => n.id === id);

    if (contact) {
      var pos = state.project.contacts.indexOf(contact);
      state.project.contacts.splice(pos, 1);
    }
  },
  'PROJECT_NOTE_SET_NEW'(state, user) {
    let userName = user.firstName + ' ' + user.lastName;
    state.note = { id: uuid(), name: userName, text: null }
  },
  'PROJECT_NOTE_SET'(state, note) {
    state.note = note
  },
  'PROJECT_NOTE_SAVE'(state, note) {
    let isExist = state.project.adminNotes.find(n => n.id === note.id);

    if (isExist) {
      var pos = state.project.adminNotes.indexOf(isExist);
      state.project.adminNotes.splice(pos, 1, note);
    }
    else {
      state.project.adminNotes.push(note);
    }
  },

  ///////////////

  'PROJECT_PREBID_NOTE_SET_NEW'(state, user) {
    let userName = user.firstName + ' ' + user.lastName;
    state.prebidNote = { id: uuid(), name: userName, text: null }
  },
  'PROJECT_PREBID_NOTE_SET'(state, note) {
    state.prebidNote = note
  },
  'PROJECT_PREBID_NOTE_SAVE'(state, note) {
    state.project.prebidNotes = [...state.project.prebidNotes.filter(i => i.id !== note.id), note];
  },

  ///////////////

  'PROJECT_TASK_SET_NEW'(state) {
    state.task = { id: uuid(), dueDate: null, completed: false }
  },
  'PROJECT_TASK_SET'(state, task) {
    state.task = task
  },
  'PROJECT_TASK_SAVE'(state, task) {
    let isExist = state.project.followUpTasks.find(n => n.id === task.id);

    if (isExist) {
      var pos = state.project.followUpTasks.indexOf(isExist);
      state.project.followUpTasks.splice(pos, 1, task);
    }
    else {
      state.project.followUpTasks.push(task);
    }
  },
  'PROJECT_TASK_UPDATE_COMPLETED_FLAG'(state, params) {
    let { id, completed } = params;
    let task = state.project.followUpTasks.find(n => n.id === id);

    if (task) {
      task.isCompleted = completed;

      var pos = state.project.followUpTasks.indexOf(task);
      state.project.followUpTasks.splice(pos, 1, task);
    }
  },
  'PROJECT_TASK_REMOVE'(state, id) {
    let task = state.project.followUpTasks.find(n => n.id === id);

    if (task) {
      var pos = state.project.followUpTasks.indexOf(task);
      state.project.followUpTasks.splice(pos, 1);
    }
  }
};

export default { state, getters, actions, mutations }
