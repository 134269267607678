<template>
  <div role="dialog" aria-label="Cookie Prompt" aria-describedby="cookieconsent:desc" class="cc-window cc-banner cc-type-info cc-theme-block cc-bottom" style="" v-if="this.showPopup">
    <span id="cookieconsent:desc" class="cc-message text-white">
        This website uses cookies to enhance usability and provide you with a more personal experience.  By using this website, you agree to our use of cookies as explained in our  <a tabindex="0" class="cc-link text-white" v-bind:href="this.privacyPolicy.url"><span class="sr-only ic-sr-only">learn more about cookies on our</span>{{ this.privacyPolicy.text }}<span class="sr-only ic-sr-only">page</span>.</a>
    </span>
    <div class="cc-compliance">
      <button type="button" tabindex="0" class="cc-btn cc-dismiss" @click="clickConsent()">I Understand <span class="sr-only ic-sr-only"> - dismiss cookie message</span></button>
    </div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies';
export default {
  name: "app-cookie-consent",
  props: { },
  data: function() {
    return {
      privacyPolicy: {
        show: (process.env.VUE_APP_TEMPLATE_LOGIN_PRIVACY_POLICY_SHOW || '').toUpperCase() === 'TRUE',
        url: process.env.VUE_APP_TEMPLATE_LOGIN_PRIVACY_POLICY_URL,
        text: process.env.VUE_APP_TEMPLATE_LOGIN_PRIVACY_POLICY_TEXT
      },
      showPopup: true,
      consentCookieName:"cookies-accepted"
    };
  },
  
  methods:{
    clickConsent(){
      VueCookies.set(this.consentCookieName, "true",{expires: ''});
      this.showPopup = false;
    }
  },
  mounted() {
      this.showPopup = !(VueCookies.isKey(this.consentCookieName) && VueCookies.get(this.consentCookieName)=="true")
  }
};
</script>
