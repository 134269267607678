import { TimeZoneService } from "@/services/"

const state = {
  timezone: null,
  timezones: []
}

export const getters = {
  getTimeZone: state => state.timezone,
  getTimeZones: state => state.timezones.sort((a, b) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 })
}

export const actions = {
  fetchTimeZone: ({ commit }, id) => {
    return TimeZoneService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TIME_ZONE_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchTimeZones: ({ commit }) => {
    return TimeZoneService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TIME_ZONES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveTimeZone: ({ commit }, params) => {
    return TimeZoneService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TIME_ZONES_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeTimeZone: ({ commit }, id) => {
    return TimeZoneService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_TIME_ZONES_REMOVE', id);
        resolve();
      })
    });
  },
  resetTimeZone: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_TIME_ZONE_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_TIME_ZONE_SET'(state, timezone) {
    state.timezone = timezone
  },
  'CONFIGURATION_TIME_ZONES_SET'(state, timezones) {
    state.timezones = timezones
  },
  'CONFIGURATION_TIME_ZONES_SAVE'(state, timezone) {
    let isExist = state.timezones.find(n => n.id === timezone.id);

    if (isExist) {
      var pos = state.timezones.indexOf(isExist);
      state.timezones.splice(pos, 1, timezone);
    }
    else {
      state.timezones.push(timezone);
    }
  },
  'CONFIGURATION_TIME_ZONES_REMOVE'(state, id) {
    let timezone = state.timezones.find(n => n.id === id);

    if (timezone) {
      var pos = state.timezones.indexOf(timezone);
      state.timezones.splice(pos, 1);
    }
  },
  'CONFIGURATION_TIME_ZONE_SET_NEW' (state) {
    state.timezone = { name: null, code: null };
  }
}

export default { state, getters, actions, mutations }
