import httpClient from "@/services/base.service.js";

export default {
  sendCompanyEmail: function (email) {
    return httpClient.post('/api/v1/email/company', email)
  },
  sendIndividual: function (email) {
    return httpClient.post('/api/v1/email/individual', email)
  }
};
