import { sortOrder } from "@/utils/sort";
import { BidInvitationListService } from "@/services/"

const state = {
    lists: [],
}

export const getters = {
    getBidInvitationListsForLoggedInUser: state => state.lists.sort(sortOrder),
}

export const actions = {
    fetchBidInvitationListsForLoggedInUser: ({ commit }) => {
        return BidInvitationListService.getForLoggedInUser().then(response => {
            return new Promise((resolve) => {
                commit('CONFIGURATION_BIDINVITATION_LISTS_SET', response.data);
                resolve(response.data);
            })
        });
    },
}

export const mutations = {
    'CONFIGURATION_BIDINVITATION_LISTS_SET'(state, lists) {
        state.lists = lists
    }
}

export default { state, getters, actions, mutations }
