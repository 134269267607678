import { ZoneService } from "@/services/"

const state = {
  zone: null,
  zones: []
}

export const getters = {
  getZone: state => state.zone,
  getZones: state => state.zones.sort((a, b) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 })
}

export const actions = {
  fetchZone: ({ commit }, id) => {
    return ZoneService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ZONE_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchZones: ({ commit }) => {
    return ZoneService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ZONES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveZone: ({ commit }, params) => {
    return ZoneService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ZONES_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeZone: ({ commit }, id) => {
    return ZoneService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ZONES_REMOVE', id);
        resolve();
      })
    });
  },
  resetZone: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_ZONE_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_ZONE_SET'(state, zone) {
    state.zone = zone
  },
  'CONFIGURATION_ZONES_SET'(state, zones) {
    state.zones = zones
  },
  'CONFIGURATION_ZONES_SAVE'(state, zone) {
    let isExist = state.zones.find(n => n.id === zone.id);

    if (isExist) {
      var pos = state.zones.indexOf(isExist);
      state.zones.splice(pos, 1, zone);
    }
    else {
      state.zones.push(zone);
    }
  },
  'CONFIGURATION_ZONES_REMOVE'(state, id) {
    let zone = state.zones.find(n => n.id === id);

    if (zone) {
      var pos = state.zones.indexOf(zone);
      state.zones.splice(pos, 1);
    }
  },
  'CONFIGURATION_ZONE_SET_NEW' (state) {
    state.zone = { name: null, abbreviation: null, sortOrder: 0 };
  }
}

export default { state, getters, actions, mutations }
