import httpClient from "@/services/base.service.js";

export default {
  getAll: function () {
    return httpClient.get("/api/v1/owner/get");
  },
  GetAllByProjectId: function (projectId) {
    return httpClient.get("/api/v1/owner/getallbyprojectid/" + projectId);
  },
  getByTypeahead: function (query) {
    return httpClient.get("/api/v1/owner/gettbyypeahead?query=" + query);
  },
  getById: function (id) {
    return httpClient.get("/api/v1/owner/get/" + id);
  },
  save: function ({ data, isEdit }) {
    let http = isEdit ? 'put' : 'post'
    let url = isEdit ? '/api/v1/owner/put/' + data.id : '/api/v1/owner/post';
    return httpClient({ method: http, url: url, data: data });
  },
  search: function (searchCriteria) {
    return httpClient.post('/api/v1/owner/search', searchCriteria)
  },
  delete: function (id) {
    return httpClient.delete('/api/v1/owner/delete/' + id);
  }
};
