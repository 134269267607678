// Components
import Vue from 'vue'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select';
import Fragment from 'vue-fragment'
import Loading from 'vue-loading-overlay';

// Filters
import CurrencyFilter from '@/filters/currency';
import { DateTimeFilter, DateOnlyFilter } from '@/filters/datetime';
import FullNameFilter from '@/filters/fullname';
import UrlFilter from '@/filters/url';
import CapitalizeFilter from '@/filters/capitalize';
import CompanyJoinAcceptanceStatusFilter from '@/filters/companyJoinAcceptanceStatus';

// Plugins
import PermissionPlugin from "@/plugins/permissions";

// Styles
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-select/dist/vue-select.css';
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import 'vue-loading-overlay/dist/vue-loading.css'

import '@/styles/loading.css';
import '@/styles/loading-btn.css';

import App from './App.vue'
import router from './router/'
import store from './store/'

Vue.config.productionTip = process.env.NODE_ENV === 'development'
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.debug = process.env.NODE_ENV === 'development'
Vue.config.silent = process.env.NODE_ENV === 'production'

Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.use(VeeValidate)
Vue.use(Fragment.Plugin)
Vue.use(PermissionPlugin, { store });
Vue.component('multiselect', Multiselect)
Vue.component('v-select', vSelect);
Vue.filter('currency', CurrencyFilter)
Vue.filter('datetime', DateTimeFilter);
Vue.filter('dateonly', DateOnlyFilter);
Vue.filter('fullname', FullNameFilter);
Vue.filter('url', UrlFilter);
Vue.filter('capitalize', CapitalizeFilter);
Vue.filter('companyJoinAcceptanceStatusFilter', CompanyJoinAcceptanceStatusFilter);
Vue.component('loading', Loading);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
