import { UserService } from "@/services/";

const state = {
  user: null,
  users: []
}

export const getters = {
  getUser: state => state.user,
  getUsers: state => state.users
}

export const actions = {
  fetchUser: ({ commit }, id) => {
    return UserService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_USER_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchUsers: ({ commit }) => {
    return UserService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_USERS_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveUser: ({ commit }, params) => {
    return UserService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_USERS_SAVE', response.data);
        resolve(response.data);
      })
    });
  },
  removeUser: ({ commit }, id) => {
    return UserService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_USERS_REMOVE', id);
        resolve();
      })
    });
  },
  updateUser: ({ commit }, user) => {
    return UserService.profile(user).then(response => {
      return new Promise(resolve => {
        commit('CONFIGURATION_USER_SET', response.data);
        resolve(response.data);
      });
    });
  },
  resetUser: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_USER_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_USER_SET'(state, user) {
    state.user = user
  },
  'CONFIGURATION_USERS_SET'(state, users) {
    state.users = users
  },
  'CONFIGURATION_USERS_SAVE' (state, user) {
    let isExist = state.users.find(n => n.id === user.id);

    if (isExist) {
      var pos = state.users.indexOf(isExist);
      state.users.splice(pos, 1, user);
    }
    else {
      state.users.push(user);
    }
  },
  'CONFIGURATION_USERS_REMOVE'(state, id) {
    let user = state.users.find(n => n.id === id);

    if (user) {
      var pos = state.users.indexOf(user);
      state.users.splice(pos, 1);
    }
  },
  'CONFIGURATION_USER_SET_NEW' (state) {
    state.user = { firstName: null, lastName: null, email: null, password: null, isClientAdmin: false, roleIds: [], companies: [], zones: [] };
  }
}

export default { state, getters, actions, mutations }
