import format from 'date-fns/format';

const DateTimeFilter = (value, pattern = 'MMM dd, yyyy') => {
  if (!value) return '';

  let [year, month, day] = value.substr(0, 10).split('-');
  let [hour, minute] = value.substr(11, 5).split(':');

  try { return format(new Date(year, (month - 1), day, hour, minute), pattern); }
  catch { return value; }
}

const DateOnlyFilter = (value) => {
    if (!value) return '';
    
    try { return value.split('T')[0]; }
    catch { return value; }
}

export { DateTimeFilter, DateOnlyFilter };
