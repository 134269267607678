import httpClient from "@/services/base.service.js";

export default {
  getById: function (id) {
    return httpClient.get("/api/v1/advertisement/get/" + id);
  },
  save: function ({ data, isEdit }) {
    const transform = (data) => {
      let formData = new FormData();
      Object.keys(data).forEach(key => { formData.append(key, data[key]); });
      return formData;
    }

    if (!isEdit) {
      return httpClient.post('/api/v1/advertisement/post', data, { headers: { 'Content-Type': 'multipart/form-data' }, transformRequest: transform })
    }

    return httpClient.put('/api/v1/advertisement/put/' + data.id, data);
},
  delete: function (id, code) {
    return httpClient.delete(`/api/v1/advertisement/delete/${id}/${code}`);
  }
};
