import httpClient from "@/services/base.service.js";

export default {
  requestToBid: function (params) {
    return httpClient.post('/api/v1/projectbid/requesttobid', params)
  },
  removeFromBid: function (params) {
    return httpClient.post('/api/v1/projectbid/removefrombid', params)
  }
};
