import { alpha as alphaSort } from "@/utils/sort";
import { RoleService } from "@/services/"

const state = {
  role: null,
  roles: []
}

export const getters = {
  getRole: state => state.role,
  getRoles: state => state.roles.sort((a,b) => alphaSort(a.name, b.name)),
  getMembershipRoles: state => state.roles.filter(role => role.isMembershipType)
}

export const actions = {
  fetchRole: ({ commit }, id) => {
    return RoleService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ROLE_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchRoles: ({ commit }) => {
    return RoleService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ROLES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveRole: ({ commit }, params) => {
    return RoleService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ROLES_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeRole: ({ commit }, id) => {
    return RoleService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ROLES_REMOVE', id);
        resolve();
      })
    });
  },
  resetRole: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_ROLE_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_ROLE_SET'(state, role) {
    state.role = role
    state.role.permissionIds = state.role.permissionIds || [];
  },
  'CONFIGURATION_ROLES_SET'(state, roles) {
    state.roles = roles
  },
  'CONFIGURATION_ROLES_SAVE' (state, role) {
    let isExist = state.roles.find(n => n.id === role.id);

    if (isExist) {
      var pos = state.roles.indexOf(isExist);
      state.roles.splice(pos, 1, role);
    }
    else {
      state.roles.push(role);
    }
  },
  'CONFIGURATION_ROLES_REMOVE'(state, id) {
    let role = state.roles.find(n => n.id === id);

    if (role) {
      var pos = state.roles.indexOf(role);
      state.roles.splice(pos, 1);
    }
  },
  'CONFIGURATION_ROLE_SET_NEW' (state) {
    state.role = { name: null, isMembershipType: false, permissionIds: [] };
  }
}

export default { state, getters, actions, mutations }
