import { AdvertisementGroupService, AdvertisementService } from "@/services/";
import { alpha as alphaSort } from "@/utils/sort";

const state = {
  advertisement: null,
  advertisements: [],
  advertisementGroups: [],
  isAdvertisementsEnabled: false
}

export const getters = {
  getAdvertisment: state => state.advertisement,
  getAdvertisments: state => state.advertisements.sort((a,b) => alphaSort(a.name, b.name)),
  getAdvertismentGroups: state => state.advertisementGroups.sort((a,b) => alphaSort(a.name, b.name)),
  getIsAdvertisementsEnabled: state => state.isAdvertisementsEnabled
}

export const actions = {
  fetchAdvertisement: ({ commit }, id) => {
    return AdvertisementService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ADVERTISMENT_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchAdvertisements: ({ commit }) => {
    AdvertisementGroupService.getListings().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ADVERTISMENTS_SET', response.data);
        !response.data.length || commit('CONFIGURATION_ADVERTISMENTS_ENABLED_SET', response.data[0].isActive);
        resolve(response.data);
      })
    });
  },
  fetchAdvertisementGroups: ({ commit }) => {
    return AdvertisementGroupService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ADVERTISMENT_GROUPS_SET', response.data);
        resolve(response.data);
      })
    });
  },
  toggleAdvertisementsEnabled: ({ commit }, direction) => {
    return AdvertisementGroupService.toggle(direction).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ADVERTISMENTS_ENABLED_SET', direction);
        resolve(direction);
      })
    });
  },
  saveAdvertisement: ({ commit, dispatch }, advertisement) => {
    return AdvertisementService.save(advertisement).then(response => {
      dispatch('fetchAdvertisementGroups');

      return new Promise((resolve) => {
        commit('CONFIGURATION_ADVERTISMENTS_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeAdvertisement: ({ commit, dispatch }, params) => {
    return AdvertisementService.delete(params.id, params.code).then(() => {
      dispatch('fetchAdvertisementGroups');
      dispatch('fetchAdvertisements');

      return new Promise((resolve) => {
        commit('CONFIGURATION_ADVERTISMENTS_REMOVE', params);
        resolve();
      })
    });
  },
  resetAdvertisement: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_ADVERTISMENT_SET_NEW');
      resolve();
    });
  },
}

export const mutations = {
  'CONFIGURATION_ADVERTISMENT_SET'(state, advertisement) {
    state.advertisement = advertisement
  },
  'CONFIGURATION_ADVERTISMENTS_ENABLED_SET' (state, isEnabled) {
    state.isAdvertisementsEnabled = isEnabled;
  },
  'CONFIGURATION_ADVERTISMENTS_SET'(state, advertisements) {
    state.advertisements = advertisements;
  },
  'CONFIGURATION_ADVERTISMENT_GROUPS_SET' (state, groups) {
    state.advertisementGroups = groups;
  },
  'CONFIGURATION_ADVERTISMENTS_SAVE' (state, advertisement) {
    state.advertisements.forEach(group => {
      let inGroup = advertisement.advertisementGroups.find(g => group.id === g);
      group.advertisements = [...group.advertisements.filter(g => g.id !== advertisement.id)];
      if (inGroup) group.advertisements.push(advertisement);
    })
  },
  'CONFIGURATION_ADVERTISMENTS_REMOVE'(state, { code, id }) {
    let group = state.advertisements.find(g => g.code === code);
    if (group) {
      group.advertisements = [...group.advertisements.filter(i => i.id !== id)];
    }
  },
  'CONFIGURATION_ADVERTISMENT_SET_NEW' (state) {
    state.advertisement = { advertisementGroups: [], zones: [] };
  }
}

export default { state, getters, actions, mutations }
