import { PublicCompanyJoinAcceptanceStatus } from "@/utils/enums";

const statusFilter = (val) => {    
    switch (val) {
        case PublicCompanyJoinAcceptanceStatus.Approved:
          return "Approved";
        case PublicCompanyJoinAcceptanceStatus.Pending:
          return "Pending Approval";       
        default:
          return "Rejected";
      }
}

export default statusFilter
