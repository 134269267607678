import { sortOrder } from "@/utils/sort";
import { AddressBookService } from "@/services/"

const state = {
  addressBook: {
    name: null,
    contacts: []
  },
  addressBooks: [],
}

export const getters = {
  getAddressBook: state => state.addressBook,
  getAddressBooks: state => state.addressBooks.sort(sortOrder),
}

export const actions = {
  fetchAddressBook: ({ commit }, id) => {
    return AddressBookService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ADDRESS_BOOK_SET', response.data)
        resolve(response.data);
      })
    });
  },
  fetchAddressBooks: ({ commit }) => {
    return AddressBookService.getForLoggedInUser().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ADDRESS_BOOKS_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveAddressBook: ({ commit }, params) => {
    return AddressBookService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ADDRESS_BOOKS_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeAddressBook: ({ commit }, id) => {
    return AddressBookService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_ADDRESS_BOOKS_REMOVE', id);
        resolve();
      })
    });
  },
  resetAddressBook: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_ADDRESS_BOOK_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_ADDRESS_BOOK_SET'(state, addressBook) {
    state.addressBook = { ...addressBook, contacts: [] };
  },
  'CONFIGURATION_ADDRESS_BOOK_SET_NEW'(state) {
    state.addressBook = { name: null, contacts: [] };
  },
  'CONFIGURATION_ADDRESS_BOOKS_SET'(state, addressBooks) {
    state.addressBooks = addressBooks
  },
  'CONFIGURATION_ADDRESS_BOOKS_SAVE'(state, addressBook) {
    let isExist = state.addressBooks.find(n => n.id === addressBook.id);

    if (isExist) {
      var pos = state.addressBooks.indexOf(isExist);
      state.addressBooks.splice(pos, 1, addressBook);
    }
    else {
      state.addressBooks.push(addressBook);
    }
  },
  'CONFIGURATION_ADDRESS_BOOKS_REMOVE'(state, id) {
    let addressBook = state.addressBooks.find(n => n.id === id);

    if (addressBook) {
      var pos = state.addressBooks.indexOf(addressBook);
      state.addressBooks.splice(pos, 1);
    }
  }
}

export default { state, getters, actions, mutations }
