import { CategoryService, ClassificationTypeService, FundingService, PermissionGroupService, ProvinceService, CountryService } from "@/services/"
import { alpha as alphaSort } from "@/utils/sort";

const state = {
  categories: [],
  classificationTypes: [],
  fundingTypes: [],
  groupedCategories: [],
  permissionGroups: [],
  provinces: [],
  countries:[]
}

export const getters = {
  getCategories: state => state.categories,
  getGroupedCategories: state => state.groupedCategories,
  getClassificationTypes: state => state.classificationTypes,
  getFundingTypes: state => state.fundingTypes,
  getPermissionGroups: state => state.permissionGroups,
  getProvinces: state => state.provinces.sort((a, b) => alphaSort(a.name, b.name)),
  getCountries: state => state.countries.sort((a, b) => alphaSort(a.name, b.name)),
}

export const actions = {
  fetchCategories: ({ commit }) => {
    return CategoryService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('LOOKUP_CATEGORY_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchGroupedCategories: ({ commit }) => {
    return CategoryService.getByGrouping().then(response => {
      return new Promise((resolve) => {
        commit('LOOKUP_CATEGORY_GROUPED_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchClassificationTypes: ({ commit }) => {
    return ClassificationTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('LOOKUP_CLASSIFICATION_TYPES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchFundingTypes: ({ commit }) => {
    return FundingService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('LOOKUP_FUNDING_TYPES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchPermissionGroups: ({ commit }) => {
    return PermissionGroupService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('LOOKUP_PERMISSION_GROUP_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchProvinces: ({ commit }) => {
    return ProvinceService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('LOOKUP_PROVINCES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchCountries: ({ commit }) => {
    return CountryService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('LOOKUP_COUNTRIES_SET', response.data);
        resolve(response.data);
      })
    });
  }
}

export const mutations = {
  'LOOKUP_CATEGORY_SET'(state, categories) {
    state.categories = categories
  },
  'LOOKUP_CATEGORY_GROUPED_SET'(state, categories) {
    state.groupedCategories = categories
  },
  'LOOKUP_CLASSIFICATION_TYPES_SET'(state, types) {
    state.classificationTypes = types
  },
  'LOOKUP_CONTACT_TYPES_SET'(state, types) {
    state.contactTypes = types
  },
  'LOOKUP_FUNDING_TYPES_SET'(state, types) {
    state.fundingTypes = types
  },
  'LOOKUP_OWNER_TYPES_SET'(state, types) {
    state.ownerTypes = types
  },
  'LOOKUP_PERMISSION_GROUP_SET'(state, groups) {
    state.permissionGroups = groups
  },
  'LOOKUP_PROCUREMENT_TYPES_SET'(state, types) {
    state.procurementTypes = types
  },
  'LOOKUP_PROVINCES_SET'(state, provinces) {
    state.provinces = provinces
  },
  'LOOKUP_COUNTRIES_SET'(state, countries) {
    state.countries = countries
  },
  'LOOKUP_TENDER_STAGES_SET'(state, stages) {
    state.tenderStages = stages
  },
  'LOOKUP_TRADE_AGREEMENTS_SET'(state, agreements) {
    state.tradeAgreements = agreements
  },
  'LOOKUP_ZONES_SET'(state, zones) {
    state.zones = zones
  }
}

export default { state, getters, actions, mutations }
