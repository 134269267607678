import httpClient from "@/services/base.service.js";

export default {
  getAll: function () {
    return httpClient.get("/api/v1/participanttype/get");
  },
  getById: function (id) {
    return httpClient.get("/api/v1/participanttype/get/" + id);
  },
  save: function ({ data, isEdit }) {
      let http = isEdit ? 'put' : 'post'
      let url = isEdit ? '/api/v1/participanttype/put/' + data.id : '/api/v1/participanttype/post';
      return httpClient({ method: http, url: url, data: data });
  },
  delete: function (id) {
    return httpClient.delete("/api/v1/participanttype/delete/" + id);
  }
};
