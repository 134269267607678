import { ServiceRegionService } from "@/services/"
import { alpha as alphaSort } from "@/utils/sort";

const state = {
  serviceRegion: null,
  serviceRegions: []
}

export const getters = {
  getServiceRegion: state => state.serviceRegion,
  getServiceRegions: state => state.serviceRegions.sort((a, b) => alphaSort(a.name,b.name))
}

export const actions = {
  fetchServiceRegion: ({ commit }, id) => {
        return ServiceRegionService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_SERVICEREGION_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchServiceRegions: ({ commit }) => {
      return ServiceRegionService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_SERVICEREGIONS_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveServiceRegion: ({ commit }, params) => {
      return ServiceRegionService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_SERVICEREGIONS_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeServiceRegion: ({ commit }, id) => {
      return ServiceRegionService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_SERVICEREGIONS_REMOVE', id);
        resolve();
      })
    });
  },
  resetServiceRegion: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_SERVICEREGION_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_SERVICEREGION_SET'(state, serviceRegion) {
    state.serviceRegion = serviceRegion
  },
  'CONFIGURATION_SERVICEREGIONS_SET'(state, serviceRegions) {
    state.serviceRegions = serviceRegions
  },
  'CONFIGURATION_SERVICEREGIONS_SAVE'(state, serviceRegion) {
      state.serviceRegions = [...state.serviceRegions.filter(i => i.id !== serviceRegion.id), serviceRegion];
  },
  'CONFIGURATION_SERVICEREGIONS_REMOVE'(state, id) {
    let serviceRegion = state.serviceRegions.find(n => n.id === id);

    if (serviceRegion) {
      var pos = state.serviceRegions.indexOf(serviceRegion);
      state.serviceRegions.splice(pos, 1);
    }
  },
  'CONFIGURATION_SERVICEREGION_SET_NEW' (state) {
    state.serviceRegion = { name: null };
  }
}

export default { state, getters, actions, mutations }
