import httpClient from "@/services/base.service.js";

export default {
    moveFiles: function (params) {
        return httpClient.post("/api/v1/file/move", params)
    },
    downloadFile: function (id, cancelToken) {
        return httpClient({
            url: "/api/v1/file/download/" + id,
            method: "GET",
            responseType: "blob",
            timeout: 0,
            cancelToken: cancelToken
        });
    },
    deleteFile: function (id, data) {
        return httpClient.delete("/api/v1/file/delete/" + id, { data });
    },
    deleteSelectedFiles: function (data) {
        return httpClient.delete("/api/v1/file/deleteselectedfiles", { data });
   },
};
