import httpClient from "@/services/base.service.js";

export default {
  create: function (params) {
    return httpClient.post('/api/v1/news/post', params);
  },
  delete: function (id) {
    return httpClient.delete("/api/v1/news/delete/" + id);
  },
  getAll: function () {
    return httpClient.get("/api/v1/news/get");
  },
  getById: function (id) {
    return httpClient.get("/api/v1/news/get/" + id);
  },
  getDashboardNews: function () {
    return httpClient.get("/api/v1/news/getdashboardnews");
  },
  save: function ({ data, isEdit }) {
    if (isEdit === true ) {
      return this.update(data);
    }
    return this.create(data);
  },
  update: function (params) {
    return httpClient.put('/api/v1/news/put/' + params.id, params);
  },
  toggle: function (direction) {
    return httpClient.put('/api/v1/news/toggle', direction);
  }
};
