import { sortOrder } from "@/utils/sort";
import { OwnerTypeService } from "@/services/";

const state = {
  ownerType: null,
  ownerTypes: []
}

export const getters = {
  getOwnerType: state => state.ownerType,
  getOwnerTypes: state => state.ownerTypes.sort(sortOrder)
}

export const actions = {
  fetchOwnerType: ({ commit }, id) => {
    return OwnerTypeService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_OWNER_TYPE_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchOwnerTypes: ({ commit }) => {
    return OwnerTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_OWNER_TYPES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveOwnerType: ({ commit }, params) => {
    return OwnerTypeService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_OWNER_TYPES_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeOwnerType: ({ commit }, id) => {
    return OwnerTypeService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_OWNER_TYPES_REMOVE', id);
        resolve();
      })
    });
  },
  resetOwnerType: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_OWNER_TYPE_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_OWNER_TYPE_SET'(state, ownerType) {
    state.ownerType = ownerType
  },
  'CONFIGURATION_OWNER_TYPES_SET'(state, ownerTypes) {
    state.ownerTypes = ownerTypes
  },
  'CONFIGURATION_OWNER_TYPES_SAVE' (state, ownerType) {
    let isExist = state.ownerTypes.find(n => n.id === ownerType.id);

    if (isExist) {
      var pos = state.ownerTypes.indexOf(isExist);
      state.ownerTypes.splice(pos, 1, ownerType);
    }
    else {
      state.ownerTypes.push(ownerType);
    }
  },
  'CONFIGURATION_OWNER_TYPES_REMOVE'(state, id) {
    let ownerType = state.ownerTypes.find(n => n.id === id);

    if (ownerType) {
      var pos = state.ownerTypes.indexOf(ownerType);
      state.ownerTypes.splice(pos, 1);
    }
  },
  'CONFIGURATION_OWNER_TYPE_SET_NEW' (state) {
    state.ownerType = { name: null, sortOrder: 0 };
  }
}

export default { state, getters, actions, mutations }
