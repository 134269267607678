const PERMISSION_TYPES = {
  // ADD PERMISSIONS
  ADD_PROJECT: 'add_project',
  ADD_PROJECT_PRIVATE: 'add_project_private',
  ADD_COMPANY: 'add_company',
  // DELETE PERMISSIONS
  DELETE_ADDRESS: 'delete_address',
  DELETE_ADMIN_NOTE: 'delete_admin_note',
  DELETE_COMPANY: 'delete_company',
  DELETE_COMPANY_MEMBERSHIP: 'delete_company_membership',
  DELETE_COMPANY_USER: 'delete_company_user',
  DELETE_CONTACT: 'delete_contact',
  DELETE_CONTACT_TYPE: 'delete_contact_type',
  DELETE_FOLLOW_UP_TASK: 'delete_follow_up_task',
  DELETE_OWNER: 'delete_owner',
  DELETE_PROJECT: 'delete_project',
  DELETE_ROLE: 'delete_role',
  DELETE_SERVICE_REGION: 'delete_service_region',
  DELETE_SITE_MEETING: 'delete_site_meeting',
  DELETE_TENDER_STAGE: 'delete_tender_stage',
  DELETE_USER: 'delete_user',
  DELETE_ZONE: 'delete_zone',
  // EDIT PERMISSIONS
  EDIT_ADDRESS: 'edit_address',
  EDIT_ADMIN_NOTE: 'edit_admin_note',
  EDIT_COMPANY: 'edit_company',
  EDIT_COMPANY_MEMBERSHIP: 'edit_company_membership',
  EDIT_COMPANY_USER: 'edit_company_user',
  EDIT_CONTACT: 'edit_contact',
  EDIT_CONTACT_TYPE: 'edit_contact_type',
  EDIT_FOLLOW_UP_TASK: 'edit_follow_up_task',
  EDIT_OWNER: 'delete_owner',
  EDIT_PROJECT: 'edit_project',
  EDIT_ROLE: 'edit_role',
  EDIT_SERVICE_REGION: 'edit_service_region',
  EDIT_SITE_MEETING: 'edit_site_meeting',
  EDIT_TENDER_STAGE: 'edit_tender_stage',
  EDIT_USER: 'edit_user',
  EDIT_ZONE: 'edit_zone',
  // VIEW PERMISSIONS
  VIEW_ADDRESS: 'view_address',
  VIEW_ADMIN_NOTE: 'view_admin_note',
  VIEW_COMPANY: 'view_company',
  VIEW_CONTACT: 'view_contact',
  VIEW_CONTACT_TYPE: 'view_contact_type',
  VIEW_FOLLOW_UP_TASK: 'view_follow_up_task',
  VIEW_OWNER: 'view_owner',
  VIEW_PROJECT: 'view_project',
  VIEW_PROJECT_DETAILS: 'view_project_details',
  VIEW_PROJECT_CONTACTS: 'view_project_contacts',
  VIEW_PROJECT_DOCUMENTS: 'view_project_documents',
  VIEW_PROJECT_BIDDERS: 'view_project_bidders',
  VIEW_ROLE: 'view_role',
  VIEW_SERVICE_REGION: 'view_service_region',
  VIEW_SITE_MEETING: 'view_site_meeting',
  VIEW_TENDER_STAGE: 'view_tender_stage',
  VIEW_USER: 'view_user',
  VIEW_ZONE: 'view_zone',
  // OTHER PERMISSIONS
  COMPANY_ADVANCED_SEARCH: 'company_advance_search',
  COMPANY_EXCEL_EXPORT: 'company_excel_export',
  COMPANY_SEARCH_COLUMNS: 'company_search_columns',
  COMPANY_SEND_EMAIL: 'company_send_email',
  ENABLE_BID_REQUEST: 'enable_bid_request',
  PROJECT_ADVANCED_SEARCH: 'project_advance_search',
  PROJECT_BULLETIN_REPORT_EXPORT: 'project_bulletin_report_export',
  PROJECT_EXCEL_EXPORT: 'project_excel_export',
  PROJECT_SEARCH_COLUMNS: 'project_search_columns',
  SEND_BID_INVITATIONS: 'send_bid_invitations',
  ENABLE_ACTIVITY_REPORT: 'enable_activity_report'
}

export default {
  install(Vue, { store }) {

    if (!store) {
      throw new Error("Please provide vuex store");
    }

    Vue.prototype.$permissions = function () {

      const PERMISSIONS = store.getters.getCurrentUserPermissions;
      const IS_SYS_ADMIN = store.state.auth.authUser ? store.state.auth.authUser.isSystemAdmin : false;
      const IS_CLIENT_ADMIN = store.state.auth.authUser ? store.state.auth.authUser.isClientAdmin : false;

      return {
        get() { return PERMISSIONS },
        get isSysAdmin() { return IS_SYS_ADMIN },
        get isClientAdmin() { return IS_CLIENT_ADMIN },
        get hasConfigurePermissions() {
            return this.hasViewOwnerPermission ||
                this.hasViewRolePermission ||
                this.hasViewServiceRegionPermissions ||
                this.hasViewUserPermission ||
                this.hasViewZonePermission ||
                this.hasSendBidInvitations
        },
        hasRequiredPermissions(requiredPermissions) {
          return requiredPermissions.some(permission => !!PERMISSIONS.find(p => p === permission));
        },
        // View Permission Getters
        get hasViewAdvertisementPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN },
        get hasViewCompanyPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_COMPANY) },
        get hasViewOwnerPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_OWNER) },
        get hasViewPaymentPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN },
        get hasViewProjectPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_PROJECT) },
        get hasViewProjectDetailsPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_PROJECT_DETAILS) },
        get hasViewProjectContactsPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_PROJECT_CONTACTS) },
        get hasViewProjectDocumentsPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_PROJECT_DOCUMENTS) },
        get hasViewProjectBiddersPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_PROJECT_BIDDERS) },
        get hasViewRolePermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_ROLE) },
        get hasViewServiceRegionPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_SERVICE_REGION) },
        get hasViewUserPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_USER) },
        get hasViewZonePermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.VIEW_ZONE) },
        // Add Permission Getters
        get hasAddCompanyPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.ADD_COMPANY) },
        get hasAddProjectPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.ADD_PROJECT) },
        get hasAddPrivateProjectPermission() { return !!PERMISSIONS.find(p => p === PERMISSION_TYPES.ADD_PROJECT_PRIVATE) },
        // Edit Permission Getters
        get hasEditCompanyPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.EDIT_COMPANY) },
        get hasEditOwnerPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.EDIT_OWNER) },
        get hasEditProjectPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.EDIT_PROJECT) },
        get hasEditRolePermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.EDIT_ROLE) },
        get hasEditServiceRegionPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.EDIT_SERVICE_REGION) },
        get hasEditUserPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.EDIT_USER) },
        get hasEditZonePermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.EDIT_ZONE) },
        // Delete Permission Getters
        get hasDeleteCompanyPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.DELETE_COMPANY) },
        get hasDeleteOwnerPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.DELETE_OWNER) },
        get hasDeleteProjectPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.DELETE_PROJECT) },
        get hasDeleteRolePermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.DELETE_ROLE) },
        get hasDeleteServiceRegionPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.DELETE_SERVICE_REGION) },
        get hasDeleteUserPermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.DELETE_USER) },
        get hasDeleteZonePermission() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.DELETE_ZONE) },
        // Other Permission Getters
        get hasCompanyAdvancedSearch() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.COMPANY_ADVANCED_SEARCH) },
        get hasCompanyExcelExport() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.COMPANY_EXCEL_EXPORT) },
        get hasCompanySearchColumns() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.COMPANY_SEARCH_COLUMNS) },
        get hasCompanySendEmail() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.COMPANY_SEND_EMAIL) },
        get hasEnableBidRequest() { return !!PERMISSIONS.find(p => p === PERMISSION_TYPES.ENABLE_BID_REQUEST) },
        get hasProjectAdvancedSearch() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.PROJECT_ADVANCED_SEARCH) },
        get hasProjectBulletinReportExport() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.PROJECT_BULLETIN_REPORT_EXPORT) },
        get hasProjectExcelExport() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.PROJECT_EXCEL_EXPORT) },
        get hasProjectSearchColumns() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.PROJECT_SEARCH_COLUMNS) },
        get hasSendBidInvitations() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.SEND_BID_INVITATIONS) },
        get hasEnableActivityReport() { return IS_SYS_ADMIN || IS_CLIENT_ADMIN || !!PERMISSIONS.find(p => p === PERMISSION_TYPES.ENABLE_ACTIVITY_REPORT) }
      }
    }
  }
}
