const urlFilter = (value) => {
  if (!value) return '';

  let url = '';

  if (!value.startsWith('http') && !value.startsWith('https')) {
    url += window.location.protocol;

    if (!value.startsWith('//')) {
      url += '//'
    }
  }

  return url += value;
}

export default urlFilter;
