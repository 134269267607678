import Vue from 'vue'
import Vuex from 'vuex'

import global from "@/store/modules/global";
import clientSettings from "@/store/modules/clientSettings";
import lookup from './modules/lookups';
import auth from './modules/auth';
import project from './modules/project';
import company from './modules/company';

import addressBooks from './modules/configuration/addressBooks';
import advertisements from './modules/configuration/advertisements';
import contactTypes from './modules/configuration/contactTypes';
import bidInvitationLists from './modules/configuration/invite-2-bid/bidInvitationLists';
import bidInvitationSelectionTypes from './modules/configuration/invite-2-bid/bidInvitationSelectionTypes';
import bidInvitationStatusTypes from './modules/configuration/invite-2-bid/bidInvitationStatusTypes';
import news from './modules/configuration/news';
import owners from './modules/configuration/owners';
import ownerTypes from './modules/configuration/ownerTypes'
import participantTypes from './modules/configuration/participantTypes';
import procurementTypes from './modules/configuration/procurementTypes';
import roles from './modules/configuration/roles';
import serviceRegions from './modules/configuration/serviceRegions';
import tenderStages from './modules/configuration/tenderStages';
import timeZones from './modules/configuration/timezones';
import tradeAgreements from './modules/configuration/tradeAgreements'
import users from './modules/configuration/users';
import zones from './modules/configuration/zones';

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  modules: {
    global,
    clientSettings,
    lookup,
    auth,
    project,
    company,
    bidInvitationLists,
    bidInvitationSelectionTypes,
    bidInvitationStatusTypes,
    addressBooks,
    advertisements,
    contactTypes,
    news,
    owners,
    ownerTypes,
    participantTypes,
    procurementTypes,
    roles,
    serviceRegions,
    tenderStages,
    timeZones,
    tradeAgreements,
    users,
    zones
  }
})

export default store;
