import uuid from 'uuid/v1'
import cloneDeep from "lodash/cloneDeep"
import { BusinessCategoryService, ClassificationOfWorkService, CompanyService, CompanySearchService, EmailService, LabourTypeService, LabourOfWorkService, LicenceService, SocialMediaTypeService, UnionAffiliationService, CompanyLiabilityInsuranceService } from "@/services/";
import { alpha as alphaSort } from "@/utils/sort";

const emptyCompany = {
  "name": null,
  "email": null,
  "website": null,
  "address": {
    "street": null,
    "suite": null,
    "city": null,
    "postalCode": null,
    "provinceId": null,
  },
  "phone": null,
  "fax": null,
  "memberships": [],
  "users": [],
  "lastLoggedIn": null,
  "businessCategoryIds": [],
  "serviceRegionIds": [],
  "labourTypeId": null,
  "labourOfWorkIds": [],
  "classificationOfWorkIds": [],
  "categoryIds": [],
  "ownerTypeIds": [],
  "matchingKeywords": [],
  "productMatchingKeywords": [],
  targetedZoneIds: [],
  showInPD: true,
  "licenceList": [],
  "associations": [],
  "projectSamples": [],
  "socialMediaList": [],
  "unionAffiliationIds": [],
  "logo": null,
  "wcbCertificate": null,
  "liabilityInsurance": null
}

const state = {
  businessCategories: [],
  labourTypes: [],
  labourOfWork: [],
  classificationOfWork: [],
  company: {
    memberships: [],
    users: [],
    licenceList: [],
    associations: [],
    projectSamples: [],
    socialMediaList: [],
    logo: {},
    wcbCertificate: {},
    liabilityInsurance: {}
  },
  companies: [],
  companyLookup: [],
  membership: {},
  user: {},
  savedSearches: [],
  sentEmail: null,
  sendEmailAddresses: [],
  association: {},
  projectSample: {},
  socialMedia: {},
  socialMediaTypes: [],
  unionAffiliations: [],
  companyLicence: {},
  licences: [],
  companyLiabilityInsurances: []
}

const companyUserSort = (a, b) => {
  if (a.acceptanceStatus < b.acceptanceStatus) return 1;
  if (a.acceptanceStatus > b.acceptanceStatus) return -1;

  if (a.user.firstName > b.user.firstName) return 1;
  if (a.user.firstName < b.user.firstName) return -1;

  if (a.user.lastName > b.user.lastName) return 1;
  if (a.user.lastName < b.user.lastName) return -1;
}

export const getters = {
  getBusinessCategories: state => state.businessCategories,
  getLabourTypes: state => state.labourTypes,
  getLabourOfWork: state => state.labourOfWork,
  getClassificationOfWork: state => state.classificationOfWork,
  getCompany: state => state.company,
  getCompanies: state => state.companies,
  getLookup: state => state.companyLookup,
  getCompanyMembership: state => state.membership,
  getCompanyMemberships: state => state.company.memberships,
  getCompanySavedSearches: state => state.savedSearches,
  getCompanySendEmailAddresses: state => state.sendEmailAddresses,
  getCompanyUser: state => state.user,
  getCompanyUsers: state => state.company.users.sort(companyUserSort), //(a, b) => alphaSort(b.showContact, a.showContact)),
  getCompanyLicenceList: state => state.company.licenceList.sort((a, b) => alphaSort(a.licence.type, b.licence.type)),
  getCompanyLicence: state => state.companyLicence,
  getLicences: state => state.licences,
  getCompanyAssociation: state => state.association,
  getCompanyAssociations: state => state.company.associations.sort((a, b) => alphaSort(a.name, b.name)),
  getCompanyProjectSample: state => state.projectSample,
  getCompanyProjectSamples: state => state.company.projectSamples.sort((a, b) => alphaSort(a.title, b.title)),
  getCompanySocialMediaList: state => state.company.socialMediaList.sort((a, b) => alphaSort(a.socialMediaType.name, b.socialMediaType.name)),
  getCompanySocialMedia: state => state.socialMedia,
  getSocialMediaTypes: state => state.socialMediaTypes,
  getUnionAffiliations: state => state.unionAffiliations,
  getCompanyLiabilityInsurances: state => state.companyLiabilityInsurances
}

export const actions = {
  fetchCompany: ({ commit }, id) => {
    if (!id) {
      return new Promise((resolve) => {
        commit('COMPANY_SET_NEW');
        resolve();
      })
    }

    return CompanyService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_SET', response.data);
        resolve(response.data);
      })
    })
  },
  fetchCompanies: ({ commit }, params) => {
    return CompanyService.search(params).then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_SET_ALL', response.data.results)
        resolve(response.data);
      });
    });
  },
  fetchAllCompanies: ({ commit }) => {
    return CompanyService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_SET_ALL', response.data)
        resolve(response.data);
      });
    });
  },
  fetchLookup: ({ commit }) => {
    return CompanyService.getLookup().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_LOOKUP_SET', response.data)
        resolve(response.data);
      });
    });
  },
  fetchCompanySearches: ({ commit }) => {
    return CompanySearchService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_SEARCHES_SET', response.data)
        resolve(response.data);
      });
    });
  },
  fetchCompanyEmailAddresses: ({ commit }, ids) => {
    return CompanyService.getUserEmails(ids).then(response => {
      return new Promise(resolve => {
        commit('COMPANY_SEND_EMAIL_ADDRESSES', response.data);
        resolve(response.data);
      });
    });
  },
  saveCompany: ({ commit }, params) => {
    params.data.memberships = state.company.memberships;
    params.data.users = state.company.users;

    return CompanyService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_SET', response.data)
        resolve(response.data);
      });
    });
  },
  saveCompanySearch: ({ commit }, params) => {
    return CompanySearchService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_SEARCHES_ADD', response.data)
        resolve(response.data);
      });
    });
  },
  removeCompanySearch: ({ commit }, id) => {
    return CompanySearchService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('COMPANY_SEARCHES_REMOVE', id)
        resolve();
      });
    })
  },
  sendCompanyEmail: ({ commit }, email) => {
    return EmailService.sendCompanyEmail(email).then(response => {
      return new Promise(resolve => {
        commit('COMPANY_EMAIL_SET', response.data);
        resolve(response.data);
      });
    });
  },
  resetCompany: ({ commit }) => {
    commit('COMPANY_SET_NEW');
  },
  fetchCompanyMembership: ({ commit }, id) => {
    return new Promise((resolve) => {
      let membership = state.company.memberships.find(p => p.id === id);

      if (membership) {
        commit('COMPANY_MEMBERSHIP_SET', membership);
        resolve(membership);
      }
    });
  },
  saveCompanyMembership: ({ commit }, membership) => {
    return new Promise(resolve => {
      commit('COMPANY_MEMBERSHIP_SAVE', membership);
      resolve()
    });
  },
  resetCompanyMembership: ({ commit }) => {
    return new Promise(resolve => {
      commit('COMPANY_MEMBERSHIP_SET_NEW');
      resolve();
    })
  },
  removeCompanyMembership: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('COMPANY_MEMBERSHIP_REMOVE', id);
      resolve();
    });
  },

  fetchCompanyUser: ({ commit }, id) => {
    return new Promise((resolve) => {
      let user = state.company.users.find(p => p.id === id);

      if (user) {
        commit('COMPANY_USER_SET', user);
        resolve(user);
      }
    });
  },
  saveCompanyUser: ({ commit }, user) => {
    if (!user.id) {
      user.id = uuid();
    }

    return new Promise(resolve => {
      commit('COMPANY_USER_SAVE', user);
      resolve()
    });
  },
  resetCompanyUser: ({ commit }) => {
    return new Promise(resolve => {
      commit('COMPANY_USER_SET_NEW');
      resolve();
    })
  },
  removeCompanyUser: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('COMPANY_USER_REMOVE', id);
      resolve();
    });
  },
  fetchBusinessCategories: ({ commit }) => {
    return BusinessCategoryService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_BUSINESS_CATEGORIES_SET', response.data);
        resolve(response.data);
      });
    });
  },
  fetchLabourTypes: ({ commit }) => {
    return LabourTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_LABOUR_TYPES_SET', response.data);
        resolve(response.data)
      })
    })
  },
  fetchLabourOfWorks: ({ commit }) => {
    return LabourOfWorkService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_LABOUR_WORKS_SET', response.data);
        resolve(response.data)
      })
    })
  },
  fetchClassificationOfWork: ({ commit }) => {
    return ClassificationOfWorkService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_CLASSIFICATION_WORK_SET', response.data);
        resolve(response.data)
      })
    })
  },
  updateCompanyUserAcceptITB: ({ commit }, param) => {
    return new Promise((resolve) => {
      commit('COMPANY_USER_ACCEPT_ITB_UPDATE', param);
      resolve();
    });
  },
  updateUserCompanyJoinStatus: ({ commit }, param) => {
    return new Promise((resolve) => {
      commit('COMPANY_USER_JOIN_STATUS_UPDATE', param);
      resolve();
    });
  },
  updateCompanyUserContact: ({ commit }, param) => {
    return new Promise((resolve) => {
      commit('COMPANY_USER_CONTACT_UPDATE', param);
      resolve();
    });
  },
  fetchLicences: ({ commit }) => {
    return LicenceService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_LICENCES_SET', response.data);
        resolve(response.data);
      });
    });
  },
  resetCompanyLicence: ({ commit }) => {
    return new Promise(resolve => {
      commit('COMPANY_LICENCE_SET_NEW');
      resolve();
    })
  },
  fetchCompanyLicence: ({ commit }, id) => {
    return new Promise((resolve) => {
      let companyLicence = state.company.licenceList.find(p => p.id === id);

      if (companyLicence) {
        commit('COMPANY_LICENCE_SET', companyLicence);
        resolve(companyLicence);
      }
    });
  },
  saveCompanyLicence: ({ commit }, companyLicence) => {
    if (!companyLicence.id) {
      companyLicence.id = uuid();
    }

    return new Promise(resolve => {
      commit('COMPANY_LICENCE_SAVE', companyLicence);
      resolve()
    });
  },
  removeCompanyLicence: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('COMPANY_LICENCE_REMOVE', id);
      resolve();
    });
  },
  saveCompanyAssociation: ({ commit }, association) => {
    if (!association.id) {
      association.id = uuid();
    }

    return new Promise(resolve => {
      commit('COMPANY_ASSOCIATION_SAVE', association);
      resolve()
    });
  },
  resetCompanyAssociation: ({ commit }) => {
    return new Promise(resolve => {
      commit('COMPANY_ASSOCIATION_SET_NEW');
      resolve();
    })
  },
  fetchCompanyAssociation: ({ commit }, id) => {
    return new Promise((resolve) => {
      let association = state.company.associations.find(p => p.id === id);

      if (association) {
        commit('COMPANY_ASSOCIATION_SET', association);
        resolve(association);
      }
    });
  },
  removeCompanyAssociation: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('COMPANY_ASSOCIATION_REMOVE', id);
      resolve();
    });
  },
  resetCompanyProjectSample: ({ commit }) => {
    return new Promise(resolve => {
      commit('COMPANY_PROJECT_SAMPLE_SET_NEW');
      resolve();
    })
  },
  fetchCompanyProjectSample: ({ commit }, id) => {
    return new Promise((resolve) => {
      let projectSample = state.company.projectSamples.find(p => p.id === id);

      if (projectSample) {
        commit('COMPANY_PROJECT_SAMPLE_SET', projectSample);
        resolve(projectSample);
      }
    });
  },
  saveCompanyProjectSample: ({ commit }, projectSample) => {
    if (!projectSample.id) {
      projectSample.id = uuid();
    }

    return new Promise(resolve => {
      commit('COMPANY_PROJECT_SAMPLE_SAVE', projectSample);
      resolve()
    });
  },
  removeCompanyProjectSample: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('COMPANY_PROJECT_SAMPLE_REMOVE', id);
      resolve();
    });
  },
  fetchSocialMediaTypes: ({ commit }) => {
    return SocialMediaTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('SOCIAL_MEDIA_TYPES_SET', response.data);
        resolve(response.data);
      });
    });
  },
  resetCompanySocialMedia: ({ commit }) => {
    return new Promise(resolve => {
      commit('COMPANY_SOCIAL_MEDIA_SET_NEW');
      resolve();
    })
  },
  fetchCompanySocialMedia: ({ commit }, id) => {
    return new Promise((resolve) => {
      let socialMedia = state.company.socialMediaList.find(p => p.id === id);

      if (socialMedia) {
        commit('COMPANY_SOCIAL_MEDIA_SET', socialMedia);
        resolve(socialMedia);
      }
    });
  },
  saveCompanySocialMedia: ({ commit }, socialMedia) => {
    if (!socialMedia.id) {
      socialMedia.id = uuid();
    }

    return new Promise(resolve => {
      commit('COMPANY_SOCIAL_MEDIA_SAVE', socialMedia);
      resolve()
    });
  },
  removeCompanySocialMedia: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit('COMPANY_SOCIAL_MEDIA_REMOVE', id);
      resolve();
    });
  },
  fetchUnionAffiliations: ({ commit }) => {
    return UnionAffiliationService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_UNION_AFFILIATION_SET', response.data);
        resolve(response.data)
      })
    })
  },
  fetchCompanyLiabilityInsurances: ({ commit }) => {
    return CompanyLiabilityInsuranceService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('COMPANY_LIABILITY_INSURANCE_SET', response.data);
        resolve(response.data)
      })
    })
  }
}

export const mutations = {
  'COMPANY_SET'(state, company) {
    state.company = company
  },
  'COMPANY_SET_ALL'(state, companies) {
    state.companies = companies
  },
  'COMPANY_LOOKUP_SET'(state, companyLookup) {
    state.companyLookup = companyLookup
  },
  'COMPANY_SET_NEW'(state) {
    state.company = cloneDeep(emptyCompany);
    state.company.id = uuid();
  },
  'COMPANY_MEMBERSHIP_SET_NEW'(state) {
    state.membership = { id: uuid(), expiryDate: null, roleId: null, zoneId: null }
  },
  'COMPANY_MEMBERSHIP_SET'(state, membership) {
    state.membership = membership
  },
  'COMPANY_MEMBERSHIP_SAVE'(state, membership) {
    let isExist = state.company.memberships.find(n => n.id === membership.id);

    if (isExist) {
      var pos = state.company.memberships.indexOf(isExist);
      state.company.memberships.splice(pos, 1, membership);
    }
    else {
      state.company.memberships.push(membership);
    }
  },
  'COMPANY_MEMBERSHIP_REMOVE'(state, id) {
    let membership = state.company.memberships.find(n => n.id === id);

    if (membership) {
      var pos = state.company.memberships.indexOf(membership);
      state.company.memberships.splice(pos, 1);
    }
  },
  'COMPANY_SEARCHES_ADD'(state, search) {
    state.savedSearches.push(search)
  },
  'COMPANY_SEARCHES_REMOVE'(state, id) {
    state.savedSearches = [...state.savedSearches.filter(i => i.id !== id)];
  },
  'COMPANY_SEARCHES_SET'(state, searches) {
    state.savedSearches = searches
  },
  'COMPANY_SEND_EMAIL_ADDRESSES'(state, emailAddresses) {
    state.sendEmailAddresses = emailAddresses;
  },
  'COMPANY_EMAIL_SET'(state, email) {
    state.sentEmail = email;
  },
  'COMPANY_USER_SET_NEW'(state) {
    state.user = { user: { firstName: null, lastName: null, email: null } }
  },
  'COMPANY_USER_SET'(state, user) {
    state.user = user
  },
  'COMPANY_USER_SAVE'(state, user) {
    let isExist = state.company.users.find(n => n.id === user.id);

    if (isExist) {
      var pos = state.company.users.indexOf(isExist);
      state.company.users.splice(pos, 1, user);
    }
    else {
      state.company.users.push(user);
    }
  },
  'COMPANY_USER_REMOVE'(state, id) {
    let user = state.company.users.find(n => n.id === id);

    if (user) {
      var pos = state.company.users.indexOf(user);
      state.company.users.splice(pos, 1);
    }
  },
  'COMPANY_BUSINESS_CATEGORIES_SET'(state, categories) {
    state.businessCategories = categories;
  },
  'COMPANY_LABOUR_TYPES_SET'(state, types) {
    state.labourTypes = types;
  },
  'COMPANY_LABOUR_WORKS_SET'(state, works) {
    state.labourOfWork = works;
  },
  'COMPANY_CLASSIFICATION_WORK_SET'(state, classifications) {
    state.classificationOfWork = classifications;
  },
  'COMPANY_USER_ACCEPT_ITB_UPDATE'(state, param) {
    let { id, shown } = param;
    let user = state.company.users.find(u => u.id === id);

    if (user) {
      user.acceptITBInvite = shown;
      state.company.users = [...state.company.users.filter(i => i.id !== user.id), user];
    }
  },
  'COMPANY_USER_JOIN_STATUS_UPDATE'(state, param) {
    let { id, status } = param;
    let user = state.company.users.find(u => u.id === id);

    if (user) {
      user.acceptanceStatus = status;
      state.company.users = [...state.company.users.filter(i => i.id !== user.id), user];
    }
  },
  'COMPANY_USER_CONTACT_UPDATE'(state, param) {
    let { id, shown } = param;
    let user = state.company.users.find(u => u.id === id);

    if (user) {
      user.showContact = shown;

      var pos = state.company.users.indexOf(user);
      state.company.users.splice(pos, 1, user);
    }
  },
  'COMPANY_LICENCES_SET'(state, licences) {
    state.licences = licences;
  },
  'COMPANY_LICENCE_SET_NEW'(state) {
    state.companyLicence = {
      expirationDate: undefined,
      issuedBy: '',
      licence: {}
    };
  },
  'COMPANY_LICENCE_SET'(state, companyLicence) {
    state.companyLicence = companyLicence
  },
  'COMPANY_LICENCE_SAVE'(state, companyLicence) {
    let isExist = state.company.licenceList.find(n => n.id === companyLicence.id);

    if (isExist) {
      var pos = state.company.licenceList.indexOf(isExist);
      state.company.licenceList.splice(pos, 1, companyLicence);
    }
    else {
      state.company.licenceList.push(companyLicence);
    }
  },
  'COMPANY_LICENCE_REMOVE'(state, id) {
    let companyLicence = state.company.licenceList.find(n => n.id === id);

    if (companyLicence) {
      var pos = state.company.licenceList.indexOf(companyLicence);
      state.company.licenceList.splice(pos, 1);
    }
  },
  'COMPANY_ASSOCIATION_SAVE'(state, association) {
    let isExist = state.company.associations.find(n => n.id === association.id);

    if (isExist) {
      var pos = state.company.associations.indexOf(isExist);
      state.company.associations.splice(pos, 1, association);
    }
    else {
      state.company.associations.push(association);
    }
  },
  'COMPANY_ASSOCIATION_SET_NEW'(state) {
    state.association = {
      name: null,
      expirationDate: null
    };
  },
  'COMPANY_ASSOCIATION_SET'(state, association) {
    state.association = association
  },
  'COMPANY_ASSOCIATION_REMOVE'(state, id) {
    let association = state.company.associations.find(n => n.id === id);

    if (association) {
      var pos = state.company.associations.indexOf(association);
      state.company.associations.splice(pos, 1);
    }
  },
  'COMPANY_PROJECT_SAMPLE_SET_NEW'(state) {
    state.projectSample = {
      title: null,
      description: null,
      image: null,
      cost: null,
      awarded: false
    };
  },
  'COMPANY_PROJECT_SAMPLE_SET'(state, projectSample) {
    state.projectSample = projectSample
  },
  'COMPANY_PROJECT_SAMPLE_SAVE'(state, projectSample) {
    let isExist = state.company.projectSamples.find(n => n.id === projectSample.id);

    if (isExist) {
      var pos = state.company.projectSamples.indexOf(isExist);
      state.company.projectSamples.splice(pos, 1, projectSample);
    }
    else {
      state.company.projectSamples.push(projectSample);
    }
  },
  'COMPANY_PROJECT_SAMPLE_REMOVE'(state, id) {
    let projectSample = state.company.projectSamples.find(n => n.id === id);

    if (projectSample) {
      var pos = state.company.projectSamples.indexOf(projectSample);
      state.company.projectSamples.splice(pos, 1);
    }
  },
  'SOCIAL_MEDIA_TYPES_SET'(state, socialMediaTypes) {
    state.socialMediaTypes = socialMediaTypes;
  },
  'COMPANY_SOCIAL_MEDIA_SET_NEW'(state) {
    state.socialMedia = {
      companySocialMediaTypeUrl: '',
      socialMediaType: {}
    };
  },
  'COMPANY_SOCIAL_MEDIA_SET'(state, socialMedia) {
    state.socialMedia = socialMedia
  },
  'COMPANY_SOCIAL_MEDIA_SAVE'(state, socialMedia) {
    let isExist = state.company.socialMediaList.find(n => n.id === socialMedia.id);

    if (isExist) {
      var pos = state.company.socialMediaList.indexOf(isExist);
      state.company.socialMediaList.splice(pos, 1, socialMedia);
    }
    else {
      state.company.socialMediaList.push(socialMedia);
    }
  },
  'COMPANY_SOCIAL_MEDIA_REMOVE'(state, id) {
    let socialMedia = state.company.socialMediaList.find(n => n.id === id);

    if (socialMedia) {
      var pos = state.company.socialMediaList.indexOf(socialMedia);
      state.company.socialMediaList.splice(pos, 1);
    }
  },
  'COMPANY_UNION_AFFILIATION_SET'(state, unionAffiliations) {
    state.unionAffiliations = unionAffiliations;
  },
  'COMPANY_LIABILITY_INSURANCE_SET'(state, companyLiabilityInsurances) {
    state.companyLiabilityInsurances = companyLiabilityInsurances;
  }
}

export default { state, getters, actions, mutations }
