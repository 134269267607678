import { sortOrder } from "@/utils/sort";
import { ParticipantTypeService } from "@/services/"

const state = {
  participantType: null,
  participantTypes: []
}

const isBidRequestOptionSort = (a, b) => {
  if (a.sortOrder < b.sortOrder) return -1;
  if (a.sortOrder > b.sortOrder) return 1;

  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
};

export const getters = {
  getParticipantType: state => state.participantType,
  getParticipantTypes: state => state.participantTypes.sort(sortOrder),
  getParticipantTypesBidRequestionOptions: state => state.participantTypes.filter(i => i.isBidRequestOption).sort(isBidRequestOptionSort)
}

export const actions = {
  fetchParticipantType: ({ commit }, id) => {
    return ParticipantTypeService.getById(id).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_PARTICIPANT_TYPE_SET', response.data);
        resolve(response.data);
      })
    });
  },
  fetchParticipantTypes: ({ commit }) => {
    return ParticipantTypeService.getAll().then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_PARTICIPANT_TYPES_SET', response.data);
        resolve(response.data);
      })
    });
  },
  saveParticipantType: ({ commit }, params) => {
    return ParticipantTypeService.save(params).then(response => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_PARTICIPANT_TYPES_SAVE', response.data);
        resolve(response);
      })
    });
  },
  removeParticipantType: ({ commit }, id) => {
    return ParticipantTypeService.delete(id).then(() => {
      return new Promise((resolve) => {
        commit('CONFIGURATION_PARTICIPANT_TYPES_REMOVE', id);
        resolve();
      })
    });
  },
  resetParticipantType: ({ commit }) => {
    return new Promise(resolve => {
      commit('CONFIGURATION_PARTICIPANT_TYPE_SET_NEW');
      resolve();
    })
  },
}

export const mutations = {
  'CONFIGURATION_PARTICIPANT_TYPE_SET'(state, participantType) {
    state.participantType = participantType
  },
  'CONFIGURATION_PARTICIPANT_TYPES_SET'(state, participantTypes) {
    state.participantTypes = participantTypes
  },
  'CONFIGURATION_PARTICIPANT_TYPES_SAVE' (state, participantType) {
    let isExist = state.participantTypes.find(n => n.id === participantType.id);

    if (isExist) {
      var pos = state.participantTypes.indexOf(isExist);
      state.participantTypes.splice(pos, 1, participantType);
    }
    else {
      state.participantTypes.push(participantType);
    }
  },
  'CONFIGURATION_PARTICIPANT_TYPES_REMOVE'(state, id) {
    let participantType = state.participantTypes.find(n => n.id === id);

    if (participantType) {
      var pos = state.participantTypes.indexOf(participantType);
      state.participantTypes.splice(pos, 1);
    }
  },
  'CONFIGURATION_PARTICIPANT_TYPE_SET_NEW' (state) {
    state.participantType = { name: null, sortOrder: 0 };
  }
}

export default { state, getters, actions, mutations }
