import httpClient from "@/services/base.service.js";

const apiBase = "/api/v1/project";

export default {
    export: function (params) {
        return httpClient({ url: `${apiBase}/excel`, method: "POST", responseType: "blob", data: params });
    },
    activityReport: function (projectId) {
        return httpClient({ url: `${apiBase}/activityreport/${projectId}`, method: "POST", responseType: "blob" });
    },
    bulletinReport: function (params) {
      return httpClient({ url: `${apiBase}/bulletinreport`, method: "POST", responseType: "blob", data: params });
    },
    getAll: function () {
        return httpClient.get(`${apiBase}/get`);
    },
    getById: function (id) {
        return httpClient.get(`${apiBase}/get/` + id);
    },
    getDetailsById: function (id) {
        return httpClient.get(`${apiBase}/details/` + id);
    },
    getForLoggedInUser: function () {
        return httpClient.get(`${apiBase}/projectsforloggedinuser`);
    },
    orderPrint: function (id) {
        return httpClient.post(`${apiBase}/orderprint/` + id);
    },
    save: function ({ data, isEdit }) {
        let http = isEdit ? 'put' : 'post'
        let url = isEdit ? `${apiBase}/put/` + data.id : `${apiBase}/post`;
        return httpClient({ method: http, url: url, data: data });
    },
    search: function (searchCriteria) {
        return httpClient.post(`${apiBase}/search`, searchCriteria)
    },
    searchWithKeywordDensity: function (searchCriteria) {
        return httpClient.post('/api/v1/project/search/keyworddensity', searchCriteria)
    },
    subscribe: function (id) {
        return httpClient.post(`${apiBase}/subscribe/` + id);
    },
    unsubscribe: function (id) {
        return httpClient.post(`${apiBase}/unsubscribe/` + id);
    },
    sendToBCBid(id) {
        return httpClient.post(`${apiBase}/sendtobcbid/` + id);
    }
};
