import { ClientService } from "@/services/"

const dbKeys = {
  CLIENT_API_KEY: 'clientApiKey',
  EXCLUDE_ZONES_FROM_DASHBOARD: 'excludeZonesFromDashboard',
  GOOGLE_MAP_KEY: 'googleMapKey',
  PUBLIC_DIRECTORY_LINK: 'publicDirectoryLink',
  USE_MEMBERSHIP_PAYMENT: 'useMembershipPayment',
  USE_DISPLAY_NEWS: 'useDisplayNews',
  USE_PROJECT_PAYMENT: 'useProjectPayment',
  USE_INVITE_2_BID: 'useInvite2Bid',
  USE_DOCUMENT_SEARCH: 'useDocumentSearch',
  USE_SEND_TO_BC_BID: 'useSendToBCBid',
  USE_PAYMENT_REPORTS: 'usePaymentReports',
  USE_ORDER_PRINTS: 'useOrderPrints'
};

const state = {
  isDataLoaded: false,
  clientApiKey: null,
  excludeZonesFromDashboard: null,
  googleMapKey: null,
  publicDirectoryLink: null,
  useDisplayNews: false,
  useMembershipPayment: false,
  useProjectPayment: false,
  useInvite2Bid: false,
  useDocumentSearch: false,
  useSendToBCBid: false,
  usePaymentReports: false,
  useOrderPrints: false,
  defaultTimeZone: null
}

export const getters = {
  isDataLoaded: state => state.isDataLoaded,
  getClientApiKey: state => state.clientApiKey,
  getExcludeZonesFromDashboard: state => state.excludeZonesFromDashboard,
  getGoogleMapKey: state => state.googleMapKey,
  getPublicDirectoryLink: state => state.publicDirectoryLink,
  getUseDisplayNews: state => state.useDisplayNews,
  getUseMembershipPayment: state => state.useMembershipPayment,
  getUseProjectPayment: state => state.useProjectPayment,
  getUseInvite2Bid: state => state.useInvite2Bid,
  getUseDocumentSearch: state => state.useDocumentSearch,
  getUseSendToBCBid: state => state.useSendToBCBid,
  getUsePaymentReports: state => state.usePaymentReports,
  getUseOrderPrints: state => state.useOrderPrints,
  getClientTimeZone: state => state.defaultTimeZone
}

export const actions = {
  fetchClientSettings: ({ commit, getters }) => {
    if (getters.isDataLoaded)
      return;

    return ClientService.getConfigs().then(response => {
      return new Promise((resolve) => {
        commit('CLIENT_SETTINGS_SET_IS_LOADED')
        commit('CLIENT_SETTINGS_CLIENT_API_KEY', response.data.settings)
        commit('CLIENT_SETTINGS_EXCLUDE_ZONES_FROM_DASHBOARD', response.data.settings)
        commit('CLIENT_SETTINGS_GOOGLE_MAP_KEY', response.data.settings)
        commit('CLIENT_SETTINGS_PUBLIC_DIRECTORY_LINK', response.data.settings)
        commit('CLIENT_SETTINGS_USE_DISPLAY_NEWS', response.data.settings)
        commit('CLIENT_SETTINGS_USE_MEMBERSHIP_PAYMENT', response.data.settings)
        commit('CLIENT_SETTINGS_USE_PROJECT_PAYMENT', response.data.settings)
        commit('CLIENT_SETTINGS_USE_INVITE_2_BID', response.data.settings)
        commit('CLIENT_SETTINGS_USE_DOCUMENT_SEARCH', response.data.settings)
        commit('CLIENT_SETTINGS_USE_SEND_TO_BC_BID', response.data.settings)
        commit('CLIENT_SETTINGS_USE_PAYMENT_REPORTS', response.data.settings)
        commit('CLIENT_SETTINGS_USE_ORDER_PRINTS', response.data.settings)
        commit('CLIENT_DEFAULT_TIME_ZONE', response.data.timeZone)
        resolve();
      });
    })
  }
}

export const mutations = {
  'CLIENT_SETTINGS_SET_IS_LOADED' (state) {
    state.isDataLoaded = true;
  },
  'CLIENT_SETTINGS_CLIENT_API_KEY'(state, clientSettings) {
    let clientApiKey = clientSettings.find(setting => setting.clientSettingKey === dbKeys.CLIENT_API_KEY);
    if (clientApiKey) {
      state.clientApiKey = clientApiKey.value;
    }
  },
  'CLIENT_SETTINGS_EXCLUDE_ZONES_FROM_DASHBOARD'(state, clientSettings) {
    let excludeZonesFromDashboard = clientSettings.find(setting => setting.clientSettingKey === dbKeys.EXCLUDE_ZONES_FROM_DASHBOARD);
    if (excludeZonesFromDashboard) {
      state.excludeZonesFromDashboard = excludeZonesFromDashboard.value;
    }
  },
  'CLIENT_SETTINGS_GOOGLE_MAP_KEY'(state, clientSettings) {
    let googleMapKey = clientSettings.find(setting => setting.clientSettingKey === dbKeys.GOOGLE_MAP_KEY);
    if (googleMapKey) {
      state.googleMapKey = googleMapKey.value;
    }
  },
  'CLIENT_SETTINGS_PUBLIC_DIRECTORY_LINK'(state, clientSettings) {
    let publicDirectoryLink = clientSettings.find(setting => setting.clientSettingKey === dbKeys.PUBLIC_DIRECTORY_LINK);
    if (publicDirectoryLink) {
      state.publicDirectoryLink = publicDirectoryLink.value;
    }
  },
  'CLIENT_SETTINGS_USE_DISPLAY_NEWS' (state, clientSettings) {
    let useDisplayNews = clientSettings.find(setting => setting.clientSettingKey === dbKeys.USE_DISPLAY_NEWS);
    if (useDisplayNews) {
      state.useDisplayNews = useDisplayNews.value;
    }
  },
  'CLIENT_SETTINGS_USE_MEMBERSHIP_PAYMENT'(state, clientSettings) {
    let useMembershipPayment = clientSettings.find(setting => setting.clientSettingKey === dbKeys.USE_MEMBERSHIP_PAYMENT);
    if (useMembershipPayment) {
      state.useMembershipPayment = useMembershipPayment.value;
    }
  },
  'CLIENT_SETTINGS_USE_PROJECT_PAYMENT'(state, clientSettings) {
    let useProjectPayment = clientSettings.find(setting => setting.clientSettingKey === dbKeys.USE_PROJECT_PAYMENT);
    if (useProjectPayment) {
      state.useProjectPayment = useProjectPayment.value;
    }
  },
  'CLIENT_SETTINGS_USE_INVITE_2_BID'(state, clientSettings) {
    let useInvite2Bid = clientSettings.find(setting => setting.clientSettingKey === dbKeys.USE_INVITE_2_BID);
    if (useInvite2Bid) {
      state.useInvite2Bid = useInvite2Bid.value;
    }
  },
  'CLIENT_SETTINGS_USE_DOCUMENT_SEARCH'(state, clientSettings) {
    let useDocumentSearch = clientSettings.find(setting => setting.clientSettingKey === dbKeys.USE_DOCUMENT_SEARCH);
    if (useDocumentSearch) {
      state.useDocumentSearch = useDocumentSearch.value;
    }
  },
  'CLIENT_SETTINGS_USE_SEND_TO_BC_BID'(state, clientSettings) {
    let useSendToBCBid = clientSettings.find(setting => setting.clientSettingKey === dbKeys.USE_SEND_TO_BC_BID);
    if (useSendToBCBid) {
      state.useSendToBCBid = useSendToBCBid.value;
    }
  },
  'CLIENT_SETTINGS_USE_PAYMENT_REPORTS'(state, clientSettings) {
    let usePaymentReports = clientSettings.find(setting => setting.clientSettingKey === dbKeys.USE_PAYMENT_REPORTS);
    if (usePaymentReports) {
      state.usePaymentReports = usePaymentReports.value;
    }
  },
  'CLIENT_SETTINGS_USE_ORDER_PRINTS'(state, clientSettings) {
    let useOrderPrints = clientSettings.find(setting => setting.clientSettingKey === dbKeys.USE_ORDER_PRINTS);
    if (useOrderPrints) {
      state.useOrderPrints = useOrderPrints.value;
    }
  },
  'CLIENT_DEFAULT_TIME_ZONE'(state, timezone) {
    state.defaultTimeZone = timezone;
  },
}

export default { state, getters, actions, mutations }
