import httpClient from "@/services/base.service.js";

export default {
    getById: function (id) {
        return httpClient.get("/api/v1/folder/get/" + id);
    },
    createFolder: function (data) {
        return httpClient.post("/api/v1/folder/post", data);
    },
    deleteFolder: function (id) {
        return httpClient.delete("/api/v1/folder/delete/" + id);
    },
    deleteSelectedFolders: function (ids) {
        return httpClient.post("/api/v1/folder/deleteselectedfolders", ids);
    },
    download: function (id, cancelToken) {
        return httpClient({
            url: "/api/v1/folder/download/" + id,
            method: "GET",
            responseType: "blob",
            timeout: 0,
            cancelToken: cancelToken
        });
    },
    downloadSelectedFiles: function (id, data, cancelToken) {
        return httpClient.post("/api/v1/folder/downloadselected/" + id, data, {
            responseType: "blob",
            timeout: 0,
            cancelToken: cancelToken
        })
    },
    getDisplayStructure: function (id) {
        return httpClient.get("/api/v1/folder/getdisplaystructure/" + id);
    },
    updateFolder: function (id, data) {
        return httpClient.put("/api/v1/folder/put/" + id, data);
    }
};
