const defaultOptions = {
  okVariant: "success",
  hideHeaderClose: true,
  centered: true
};

const confirmDefaultOptions = {
  okTitle: "Confirm"
}

const okDefaultOptions = {
  okTitle: "Ok",
}

export const confirm = function (context, title, message, overrideOptions) {
  let options = { title: title, ...defaultOptions, ...confirmDefaultOptions, ...overrideOptions };
  return context.$root.$bvModal.msgBoxConfirm(message, options)
}

export const ok = function (context, title, message, overrideOptions) {
  let options = { title: title, ...defaultOptions, ...okDefaultOptions, ...overrideOptions };
  return context.$root.$bvModal.msgBoxOk(message, options)
}

export default { confirm, ok }
