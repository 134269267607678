import httpClient from "@/services/base.service.js";

export default {
  authenticate: function (model) {
    return httpClient.post('/api/v1/user/authenticate', model)
  },
  create: function (params) {
    return httpClient.post('/api/v1/user/post', params);
  },
  delete: function (id) {
    return httpClient.delete("/api/v1/user/delete/" + id);
  },
  forgotPassword: function (params) {
    return httpClient.post("/api/v1/user/forgotpassword", params);
  },
  getAll: function () {
    return httpClient.get("/api/v1/user/get");
  },
  getById: function (id) {
    return httpClient.get("/api/v1/user/get/" + id);
  },
  profile: function (params) {
    return httpClient.patch("/api/v1/user/profile/" + params.id, params);
  },
  resetPassword: function (params) {
    return httpClient.post("/api/v1/user/passwordreset", params)
  },
  save: function ({ data, isEdit }) {
    if (isEdit === true ) {
      return this.update(data);
    }
    return this.create(data);
  },
  search: function (searchCriteria) {
    return httpClient.post('/api/v1/user/search', searchCriteria)
  },
  update: function (params) {
    return httpClient.put('/api/v1/user/put/' + params.id, params);
  },
  verifyEmail: function (email) {
    return httpClient.get('/api/v1/user/verify/email/?email=' + encodeURIComponent(email));
  }
};
