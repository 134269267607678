<template>
  <div>
    <main id="app" class="overflow-hidden" v-if="isAppInitialized">
      <loading :active.sync="isPageLoading" is-full-page></loading>
      <router-view :key="$route.fullPath" />
      <app-cookie-consent/>
    </main>
    <div v-if="!isAppInitialized">
      <span>The application is currently loading...</span>
    </div>
  </div>
</template>

<style lang="scss">
  @import '@/styles/vue-select-bootstrap4.scss';
</style>

<script>
import JwtHelper from "@/utils/jwtHelper";
import { mapActions } from 'vuex'
import { CompanyService } from "@/services/";
import MessageBox from "@/utils/messagebox";
import { EventBus } from "@/event-bus/event-bus.js";
import AppCookieConsent from "@/components/app-cookie-consent.vue";
export default {
  data: function() {
    return {
      isAppInitialized: false,
      isPageLoading: false
    }
  },
  components:{
    AppCookieConsent
  },
  methods: {
    handlePrompts() {
      let showNotifications = this.$store.state.global.showNotifications;

      if (showNotifications) {
        CompanyService.getNotifications().then(response => {
          if (response.data.type === 'update-company') {
            let message = this.$createElement('span', {}, [
              'It looks like you have not updated your Company profile and are missing out on important updates.',
              this.$createElement('br'),
              this.$createElement('br'),
              'Please go to your Company Profile and add your matching keywords and other details to ensure we have the most update and accurate details about your profile.'
            ]);

            MessageBox.ok(this, 'You should update your Company Profile', message, { size: 'lg', noCloseOnBackdrop: true, noCloseOnEsc: true });
          }
        });

        this.$store.dispatch('setNotificationsDisplay', false);
      }

    },
    async initialize() {
      let sessionJwt = JwtHelper.getJwtToken();
      let sessionUser = JwtHelper.getUserToken();

      if (sessionJwt) {
        await this.refreshUser({ token: sessionJwt, user: sessionUser });
      }

      this.isAppInitialized = true
    },
    ...mapActions(['refreshUser'])
    },
  mounted: function () {
    this.initialize();

    EventBus.$on('page-loading', (toggle) => {
      this.isPageLoading = toggle;

      if (!toggle) {
        this.handlePrompts();
      }
    })
  }
}
</script>
