import httpClient from "@/services/base.service.js";

export default {
  export: function (params) {
    return httpClient({ url: "/api/v1/payment/excel", method: "POST", responseType: "blob", data: params });
  },
  getProviderDetailsByProject: function (projectId) {
    return httpClient.get("/api/v1/payment/getproviderdetailsbyproject/" + projectId);
  },
  membershipUpgrade: function (payload) {
    return httpClient.post("/api/v1/payment/membership/upgrade", payload);
  },
  post: function (params) {
    return httpClient.post("/api/v1/payment/post", params);
  }
};
